import { AxiosRequestConfig } from "axios";
import { NotificationType } from "models";
import service from ".";

type EditNotificationType = {
  connectionId: string;
  notification: NotificationType;
};

export const GetNotifications = async (
  user_id: string,
  config?: AxiosRequestConfig
) =>
  await service.get<NotificationType[]>("/notifications", {
    ...config,
    params: { user_id }
  });

export const EditNotifications = async (
  params: EditNotificationType,
  config?: AxiosRequestConfig
) => await service.put<NotificationType>("/notifications", params, config);

import { NotificationType } from "models/notifications";
import { useUser } from "providers/user";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import * as NotificationsServices from "services/notifications";
import { exitElement, ls } from "utils";
import useMap from "hooks/map";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
const endpoint = "wss://4h5rfonmm8.execute-api.us-east-2.amazonaws.com/dev"; //ip do socket

export default function NotificationsViewModel() {
  const [notifications, { set, edit, add }] = useMap<
    string,
    NotificationType
  >();
  const [openNotifications, setOpenNoitifications] = useState(false);
  const socket = useRef<WebSocket | null>(null);
  const ref = useRef<HTMLDivElement>(null);
  const { currentUser } = useUser();
  const navigate = useNavigate();
  const { t } = useTranslation("components");
  const { invoice_id = "" } = useParams();
  const notificationsList = useMemo(
    () => Array.from(notifications?.values() ?? []).reverse(),
    [notifications]
  );

  const countNotifications = useMemo(
    () => notificationsList?.filter(notification => !notification.read),
    [notificationsList]
  );

  const GetAllNotifications = useCallback(
    async (user_id: string) =>
      await NotificationsServices.GetNotifications(user_id).then(({ data }) => {
        set(
          new Map(
            data
              .reverse()
              .map(notifiaction => [notifiaction?._id, notifiaction])
          )
        );
      }),
    [set]
  );

  const handleReadNotification = useCallback(
    async (notification: NotificationType) => {
      const connectionId = ls.getItem("socket");

      if (!connectionId) return;

      await NotificationsServices.EditNotifications({
        connectionId,
        notification
      }).then(({ data }) => {
        edit(data?._id, data);
        if (invoice_id === data?.invoice_id) window.location.reload();
        if (data?.type === "invoice")
          navigate(`/finance/invoice/${data?.invoice_id}`);
        if (data?.type === "billing_error")
          navigate(`/audit-logs/billings-logs`);
      });
    },
    [edit, navigate, invoice_id]
  );

  useEffect(() => {
    if (!currentUser?._id) return;

    GetAllNotifications(currentUser?._id);
  }, [currentUser, GetAllNotifications]);

  useEffect(() => {
    const hide = exitElement(ref, () => setOpenNoitifications(false));
    document.documentElement.addEventListener("click", hide);
    return () => document.documentElement.removeEventListener("click", hide);
  }, []);

  const onSocketOpen = useCallback(() => {
    try {
      if (currentUser) {
        socket.current?.send(
          JSON.stringify({ action: "status", user_id: currentUser._id })
        );
      }
    } catch (error) {
      console.log(error);
      //error
    }
  }, [currentUser]);

  const onSocketClose = useCallback(() => {
    socket.current?.close();
  }, []);

  const onSocketMessage = useCallback(
    (dataStr: unknown) => {
      const data = JSON.parse(dataStr as string);

      if (data.action === "status") {
        const { connectionId } = data;
        ls.setItem("socket", connectionId);
      }

      if (data.action === "new_notification") {
        const { notification } = data;
        add(notification?._id, notification);
        return;
      }

      if (data.action === "notification_read") {
        const { notification } = data;

        edit(notification?._id, notification);
      }
    },
    [edit, add]
  );

  const onConnect = useCallback(() => {
    if (socket.current?.readyState !== WebSocket.OPEN) {
      socket.current = new WebSocket(endpoint);
      socket.current.addEventListener("open", onSocketOpen);
      socket.current.addEventListener("close", onSocketClose);
      socket.current.addEventListener("message", event => {
        onSocketMessage(event.data);
      });
    }
  }, [onSocketOpen, onSocketClose, onSocketMessage]);

  useEffect(() => {
    return () => {
      socket.current?.close();
    };
  }, []);

  useEffect(() => {
    if (currentUser) {
      onConnect();
    }
  }, [onConnect, currentUser]);

  return {
    notificationsList,
    openNotifications,
    notifications,
    countNotifications,
    ref,
    t,
    navigate,
    handleReadNotification,
    setOpenNoitifications
  };
}

import { InvoiceResponse } from "models/invoices";
import { useToast } from "providers/toast";
import { useUser } from "providers/user";
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import * as InvoicesService from "services/invoices";
import { InvoiceErrors } from "./errors";

type FinanceContextType = {
  invoices?: InvoiceResponse[];
  invoice?: InvoiceResponse;
  loading: boolean;
  loadingAll: boolean;
  setInvoiceID: React.Dispatch<React.SetStateAction<string>>;
  getInvoices: (userId: string) => Promise<void>;
  getInvoice: () => Promise<void>;
  sendWorkFlowCheck: (message: string) => Promise<void>;
  sendWorkFlowApprove: () => Promise<void>;
  sendWorkFlowRefuse: (message: string) => Promise<void>;
  sendWorkFlowReview: (message: string) => Promise<void>;
};

const FinanceContext = createContext({} as FinanceContextType);

type FinanceProviderType = {
  children: ReactNode;
};

const FinanceProvider = ({ children }: FinanceProviderType) => {
  const [invoices, setInvoices] = useState<InvoiceResponse[]>();
  const [invoice, setInvoice] = useState<InvoiceResponse>();
  const [invoiceID, setInvoiceID] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const [loadingAllInvoice, setLoadingAllInvoice] = useState(false);
  const { t } = useTranslation("financial");
  const { error, warning } = useToast();
  const { currentUser } = useUser();
  const { invoice_id = "" } = useParams();

  const errorsResolver = useMemo(
    () => new InvoiceErrors({ error, warning }, t),
    [error, warning, t]
  );

  const inLoading = useMemo(
    () => loading || invoices === undefined,
    [loading, invoices]
  );
  const inLoadingAll = useMemo(
    () => loadingAllInvoice || invoices === undefined,
    [loadingAllInvoice, invoices]
  );

  const getInvoices = useCallback(
    async (userId: string) => {
      setLoadingAllInvoice(true);
      await InvoicesService.GetInvoices(userId)
        .then(({ data }) => {
          setInvoices(data);
        })
        .catch(errorsResolver.defaultError)
        .finally(() => setLoadingAllInvoice(false));
    },
    [errorsResolver]
  );

  const getInvoice = useCallback(async () => {
    setLoading(true);
    if (currentUser?._id && invoice_id)
      await InvoicesService.GetInvoice(invoice_id, currentUser?._id)
        .then(({ data }) => {
          setInvoice(data);
        })
        .catch(errorsResolver.defaultError)
        .finally(() => setLoading(false));
  }, [currentUser, invoice_id, errorsResolver]);

  useEffect(() => {
    if (!invoiceID) setInvoiceID(invoice?._id as string);
  }, [invoice, invoiceID]);

  useEffect(() => {
    if (!currentUser?._id) return;

    getInvoices(currentUser?._id as string);
  }, [currentUser, getInvoices]);

  useEffect(() => {
    getInvoice();
  }, [invoice_id, currentUser, getInvoice]);

  // websoket
  const sendWorkFlowCheck = useCallback(
    async (message: string) => {
      if (currentUser?._id && invoice_id)
        await InvoicesService.sendWorkFlowCheck(
          invoice_id,
          currentUser?._id,
          message
        ).then();
    },
    [currentUser, invoice_id]
  );

  const sendWorkFlowApprove = useCallback(async () => {
    if (currentUser?._id && invoice_id)
      await InvoicesService.sendWorkFlowApprove(invoice_id, currentUser?._id)
        .then()
        .catch(errorsResolver.defaultError);
  }, [currentUser, invoice_id, errorsResolver]);

  const sendWorkFlowRefuse = useCallback(
    async (message: string) => {
      if (currentUser?._id && invoice_id)
        await InvoicesService.sendWorkFlowRefuse(
          invoice_id,
          currentUser?._id,
          message
        ).then();
    },
    [currentUser, invoice_id]
  );

  const sendWorkFlowReview = useCallback(
    async (message: string) => {
      if (currentUser?._id && invoice_id)
        await InvoicesService.sendWorkFlowReview(
          invoice_id,
          currentUser?._id,
          message
        ).then();
    },
    [currentUser, invoice_id]
  );

  return (
    <FinanceContext.Provider
      value={{
        invoices,
        invoice,
        loading: inLoading,
        loadingAll: inLoadingAll,
        getInvoice,
        getInvoices,
        setInvoiceID,
        sendWorkFlowCheck,
        sendWorkFlowApprove,
        sendWorkFlowRefuse,
        sendWorkFlowReview
      }}>
      {children}
    </FinanceContext.Provider>
  );
};

export const useFinance = () => useContext(FinanceContext);

export default FinanceProvider;

import { AxiosError } from "axios";
import { Errors } from "utils";

export class AuthErrors extends Errors {
  signIn = (reason: AxiosError) => {
    switch (reason.response?.status) {
      case 401:
        this.warning({
          title: this.t("alerts.accessDenied"),
          description: this.t("alerts.invalidAccess")
        });
        break;
      case 500:
        this.error({
          description: this.t("alerts.interruptedAction")
        });
        break;
      default:
        this.error({
          description: this.t("alerts.unforeseen")
        });
        break;
    }
  };

  signUp = (reason: AxiosError) => {
    switch (reason.response?.status) {
      case 400:
        this.warning({
          title: this.t("alerts.registeredEmail"),
          description: this.t("alerts.interruptedAction")
        });
        break;
      default:
        this.defaultError(reason);
        break;
    }
  };

  activateAccount = (reason: AxiosError) => {
    switch (reason.response?.status) {
      case 422:
        this.warning({ description: this.t("alerts.invalidToken") });
        break;
      default:
        this.defaultError(reason);
        break;
    }
  };

  newPassword = (reason: AxiosError) => {
    switch (reason.response?.status) {
      case 422:
        this.warning({
          description: this.t("alerts.invalidToken")
        });
        break;
      default:
        this.defaultError(reason);
        break;
    }
  };
}

import { ReactNode, Ref } from "react";
import { LinkProps } from "react-router-dom";
import { Container } from "./styles";

type LinkType = LinkProps & {
  children: ReactNode;
  ref?: Ref<HTMLAnchorElement>;
};

const Link = ({ children, ...args }: LinkType) => {
  return <Container {...args}>{children}</Container>;
};

export default Link;

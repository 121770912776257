import { useState, useCallback } from "react";

export function useFileToBase64() {
  const [base64String, setBase64String] = useState<string | null>(null);

  const convertFileToBase64 = useCallback(async (file: File) => {
    try {
      const base64 = await fileToBase64(file);
      setBase64String(base64);
    } catch (error) {
      console.error(error);
    }
  }, []);

  const clearBase64String = useCallback(() => {
    setBase64String(null);
  }, []);

  function fileToBase64(file: File): Promise<string | null> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = () => {
        const base64String = reader.result as string;
        const base64Value = base64String.split(",")[1]; // Remove data URL prefix
        resolve(base64Value);
      };

      reader.onerror = () => {
        reject(new Error("Erro ao ler o arquivo"));
      };

      reader.readAsDataURL(file);
    });
  }

  return {
    base64String,
    convertFileToBase64,
    clearBase64String
  };
}

import { AxiosRequestConfig } from "axios";
import {
  AuthrnticationTokenRequest,
  CreatePasswordRequest,
  NewPasswordRequest,
  RecoverPasswordRequest,
  SignInRequest,
  SignInResponse,
  UpdateRequest
} from "models/auth";
import service from ".";

export const signIn = async (
  params: SignInRequest,
  config?: AxiosRequestConfig
) => await service.post<SignInResponse>("/auth/signin", params, config);

export const authenticationToken = async (
  params: AuthrnticationTokenRequest,
  config?: AxiosRequestConfig
) =>
  await service.post<SignInResponse>("/auth/cognito/signIn ", params, config);

export const createPassword = async (
  params: CreatePasswordRequest,
  config?: AxiosRequestConfig
) => await service.post(`/user/confirm`, params, config);

export const recoverPassword = async (
  params: RecoverPasswordRequest,
  config?: AxiosRequestConfig
) => await service.post("/auth/recover_password", params, config);

export const resetPassword = async (
  params: NewPasswordRequest,
  config?: AxiosRequestConfig
) => await service.post("/auth/reset_password", params, config);

export const updatePassword = async (
  params: UpdateRequest,
  config?: AxiosRequestConfig
) => await service.put("/auth/update_password", params, config);

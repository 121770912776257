import ContentLoader from "react-content-loader";

const SideBarLoader = () => (
  <ContentLoader
    speed={3}
    width={250}
    viewBox="0 0 400 150"
    backgroundColor="#cbc8c8"
    foregroundColor="#2532e9">
    <rect x="77" y="85" rx="0" ry="0" width="143" height="6" />
  </ContentLoader>
);

export default SideBarLoader;

import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useState
} from "react";

type HeaderType = {
  title?: string;
  description?: string;
  actions?: ReactNode[];
  icon?: ReactNode;
};

type HeaderContextType = {
  header?: HeaderType;
  setHeader: Dispatch<SetStateAction<HeaderType | undefined>>;
};

type HeaderProviderType = {
  children: ReactNode;
  defaultHeader?: HeaderType;
};

const HeaderContext = createContext({} as HeaderContextType);

const HeaderProvider = ({ children, defaultHeader }: HeaderProviderType) => {
  const [header, setHeader] = useState(defaultHeader);

  return (
    <HeaderContext.Provider
      value={{
        header,
        setHeader
      }}>
      {children}
    </HeaderContext.Provider>
  );
};

export const useHeader = () => useContext(HeaderContext);

export default HeaderProvider;

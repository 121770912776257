import { ReactComponent as ArrowIcon } from "assets/icons/arrowDown.svg";
import styled, { css } from "styled-components";

type NavItemDropdown = {
  $open?: boolean;
};

export const Container = styled.li`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

export const Header = styled.div<NavItemDropdown>`
  width: 100%;
  height: 4.9rem;
  display: flex;
  align-items: center;
  padding: 0.75rem 0.4rem;
  gap: 0.4rem;
  border-radius: 0.4rem;

  background: ${({ $open }) => ($open ? "var(--white)" : "transparente")};

  &:hover {
    background: var(--white);
    box-shadow: 0 0.2rem 0.4rem var(--box-shadow),
      0 0.4rem 0.4rem var(--box-shadow);
  }
`;

export const IconContent = styled.div`
  height: 3.2rem;
  width: 3.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--neutral-400);
`;

export const InfoContainer = styled.div`
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const LabelContent = styled.h3`
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.2rem;
  color: var(--neutral-400);
  text-transform: uppercase;
`;

export const ItemContent = styled.ul`
  margin: 0.3rem 0 0.3rem 3.8rem;

  a {
    color: inherit;
  }
`;

export const Item = styled.li`
  list-style: none;
  padding: 0.8rem;
  width: 100%;
  height: 4rem;
  display: flex;
  align-items: center;
  &:hover {
    background: var(--white);
    box-shadow: 0 0.2rem 0.4rem var(--box-shadow),
      0 0.4rem 0.4rem var(--box-shadow);
  }
  cursor: pointer;
`;

export const ArrowDonwIcon = styled(ArrowIcon)<NavItemDropdown>`
  width: 3.4rem;
  height: 3.4rem;
  padding: 0.9rem;

  transition: 250ms ease-in-out;
  cursor: pointer;

  ${({ $open }) =>
    $open &&
    css`
      transform: rotate(180deg);
    `}
`;

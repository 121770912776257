import Toast, { ToastOptionTypes, ToastType } from "components/toast";
import useMap from "hooks/map";
import { createContext, ReactNode, useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Container, ToastProviderStylesType } from "./styles";

export type CreateToastType = (args: Omit<ToastType, "removeMe">) => void;

type ToastContextType = {
  success: CreateToastType;
  error: CreateToastType;
  warning: CreateToastType;
  info: CreateToastType;
};

type ToastProviderType = ToastProviderStylesType & {
  children: ReactNode;
};

const ToastContext = createContext<ToastContextType>({} as ToastContextType);

const ToastProvider = ({ children, ...args }: ToastProviderType) => {
  const [toasts, { add, remove }] = useMap<string, ToastType>();

  const { t } = useTranslation();

  const createToast = useMemo(
    () =>
      function (
        this: ToastOptionTypes,
        { title, description, action }: Omit<ToastType, "removeMe">
      ): void {
        const id = (Math.random() * 1000).toFixed(0);

        add(id, {
          title: title || t(this),
          description: description,
          action,
          type: this,
          removeMe: () => remove(id)
        });
      },
    [t, add, remove]
  );

  const success = useMemo(() => createToast.bind("success"), [createToast]);
  const error = useMemo(() => createToast.bind("error"), [createToast]);
  const warning = useMemo(() => createToast.bind("warning"), [createToast]);
  const info = useMemo(() => createToast.bind("info"), [createToast]);

  return (
    <ToastContext.Provider value={{ success, error, warning, info }}>
      <Container {...args}>
        {Array.from(toasts || [])?.map(([id, props]) => (
          <Toast {...props} key={id} />
        ))}
      </Container>
      {children}
    </ToastContext.Provider>
  );
};

export const useToast = () => useContext(ToastContext);

export default ToastProvider;

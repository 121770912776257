type PropertiesTypes = {
  label: string;
  value: string;
  updated: boolean;
};

export const ObjectProperties = (params: object, updatedProps: string[]) => {
  const properties: PropertiesTypes[] = [];
  for (const [label, value] of Object.entries(params)) {
    properties.push({
      label,
      value: value as string,
      updated: updatedProps.includes(label)
    });
  }

  return properties;
};

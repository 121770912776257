import { memo, ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { ContainerOptions, NoOptionsContent } from "./styles";

export type ValueType = string | number | boolean | undefined;

export type OptionType = {
  children: ReactNode;
  value: ValueType;
  onClick?: (value: ValueType) => void;
  disable?: boolean;
};

export const NoOptions = () => {
  const { t } = useTranslation("components");

  return (
    <NoOptionsContent>
      <p>{t("select.noOptions")}</p>
    </NoOptionsContent>
  );
};

export const Option = ({
  children,
  value,
  onClick,
  disable,
  ...args
}: OptionType) => {
  return (
    <ContainerOptions
      disable={disable}
      onClick={() => !disable && onClick?.(value)}
      {...args}>
      {children}
    </ContainerOptions>
  );
};

export default memo(Option);

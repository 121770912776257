/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disabled @typescript-eslint/no-var-requires */
import { i18nLanguage, languages } from "consts";
import i18next from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

const commonsPT = require("./pt/commons.json");
const commonsEN = require("./en/commons.json");
const commonsES = require("./es/commons.json");

const componentsPT = require("./pt/components.json");
const componentsEN = require("./en/components.json");
const componentsES = require("./es/components.json");

const authPT = require("./pt/auth.json");
const authEN = require("./en/auth.json");
const authES = require("./es/auth.json");

const organizationsPT = require("./pt/organizations.json");
const organizationsEN = require("./en/organizations.json");
const organizationsES = require("./es/organizations.json");

const settingsPT = require("./pt/settings.json");
const settingsEN = require("./en/settings.json");
const settingsES = require("./es/settings.json");

const billingsPT = require("./pt/billings.json");
const billingsEN = require("./en/billings.json");
const billingsES = require("./es/billings.json");

const profilePT = require("./pt/profile.json");
const profileEN = require("./en/profile.json");
const profileES = require("./es/profile.json");

const financialPT = require("./pt/financial.json");
const financialEN = require("./en/financial.json");
const financialES = require("./es/financial.json");

const auditLogsPT = require("./pt/auditLogs.json");
const auditLogsEN = require("./en/auditLogs.json");
const auditLogsES = require("./es/auditLogs.json");

const invoicesImportPT = require("./pt/invoiceImport.json");

const invoicesExportPT = require("./pt/exportInvoice.json");

const pakageOnDemandPT = require("./pt/pakageOnDemand.json");

const resources = {
  pt: {
    commons: commonsPT,
    components: componentsPT,
    auth: authPT,
    organizations: organizationsPT,
    settings: settingsPT,
    billings: billingsPT,
    profile: profilePT,
    financial: financialPT,
    auditLogs: auditLogsPT,
    invoices: invoicesImportPT,
    invoicesExport: invoicesExportPT,
    pakageOnDemand: pakageOnDemandPT
  },
  en: {
    commons: commonsEN,
    components: componentsEN,
    auth: authEN,
    organizations: organizationsEN,
    settings: settingsEN,
    billings: billingsEN,
    profile: profileEN,
    financial: financialEN,
    auditLogs: auditLogsEN
  },
  es: {
    commons: commonsES,
    components: componentsES,
    auth: authES,
    organizations: organizationsES,
    settings: settingsES,
    billings: billingsES,
    profile: profileES,
    financial: financialES,
    auditLogs: auditLogsES
  }
};

const languagePathDetector = {
  name: "languagePathDetector",
  lookup() {
    const path = window.location.pathname;
    const firstItemPath = path?.split("/")?.[0 || 1];
    const defaultLanguage = window.localStorage.getItem(i18nLanguage) || "";
    return languages.some(language => language === firstItemPath)
      ? firstItemPath
      : defaultLanguage;
  }
};

const detector = new LanguageDetector();
detector.addDetector(languagePathDetector);

i18next
  .use(initReactI18next)
  .use(detector)
  .init({
    debug: false,
    ns: [
      "commons",
      "components",
      "auth",
      "profile",
      "journey",
      "reportsByJourney",
      "export",
      "plan",
      "organizations",
      "settings",
      "billings",
      "profile",
      "financial",
      "auditLogs",
      "invoices",
      "invoicesExport",
      "pakageOnDemand"
    ],
    defaultNS: "commons",
    fallbackNS: "commons",
    fallbackLng: languages,
    detection: {
      order: ["languagePathDetector"],
      lookupLocalStorage: i18nLanguage
    },
    interpolation: {
      escapeValue: false
    },
    resources
  });

export default i18next;

import OverflowText from "components/overflowText";
import { ReactNode, useState } from "react";
import { NavLink } from "react-router-dom";
import {
  ArrowDonwIcon,
  Container,
  Header,
  IconContent,
  InfoContainer,
  Item,
  ItemContent,
  LabelContent
} from "./styles";

type itemMenuType = {
  label: string;
  path?: string;
  action?: () => void;
};

type NavDropdownType = {
  label: string;
  text: string;
  item: itemMenuType[];
  icon?: ReactNode;
  justIcon?: boolean;
};

const NavDropdown = ({
  item,
  icon,
  label,
  text,
  justIcon
}: NavDropdownType) => {
  const [open, setOpen] = useState(false);

  return (
    <Container>
      <Header $open={open}>
        <IconContent title={`${label}: ${text}`}>{icon}</IconContent>
        {!justIcon && (
          <>
            <InfoContainer>
              <LabelContent>{label}</LabelContent>
              <OverflowText>{text}</OverflowText>
            </InfoContainer>
            <ArrowDonwIcon $open={open} onClick={() => setOpen(!open)} />
          </>
        )}
      </Header>
      {!justIcon && open && (
        <ItemContent>
          {item?.map((item, index) =>
            item.path ? (
              <NavLink key={index} to={item.path}>
                <Item>
                  <span>{item.label}</span>
                </Item>
              </NavLink>
            ) : (
              <Item onClick={item.action} key={index}>
                <span>{item.label}</span>
              </Item>
            )
          )}
        </ItemContent>
      )}
    </Container>
  );
};

export default NavDropdown;

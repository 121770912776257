import styled from "styled-components";

export const Container = styled.div`
  position: relative;
`;
export const Header = styled.div`
  width: 100%;
  border-bottom: 1px solid var(--gray);
  padding: 1.6rem;
`;

export const NotifyButton = styled.button`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3rem;
  height: 3rem;
  color: #333333;
  background: #dddddd;
  border: none;
  outline: none;
  border-radius: 50%;

  :hover {
    cursor: pointer;
  }

  :active {
    background: #cccccc;
  }
`;

export const Badge = styled.span`
  position: absolute;
  top: -1.4rem;
  right: -1.4rem;
  width: 2.6rem;
  height: 2.6rem;
  background: var(--red);
  color: var(--white);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  padding: 0.6rem;
  font-size: 1.2rem;
`;

export const NotificationsItemsContainer = styled.div`
  z-index: 1;
  position: absolute;
  right: 1rem;
  margin-top: 0.3rem;
  /* left: -1rem; */
  /* width: 100%; */
  width: 40rem;
  max-height: 70vh;
  overflow-y: scroll;
  padding: 0.8rem;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  border-radius: 0.4rem;
  background: var(--white);
  box-shadow: 0 0.2rem 0.4rem var(--box-shadow),
    0 0.4rem 0.4rem var(--box-shadow);

  div {
    width: 100%;
  }
`;

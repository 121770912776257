import AuthProvider from "providers/auth";
import ToastProvider from "providers/toast";
import GlobalStyles from "./global.styles";
import RoutesMap from "./routes";

const App = () => {
  return (
    <>
      <ToastProvider position="top-right">
        <AuthProvider>
          <RoutesMap />
          <GlobalStyles />
        </AuthProvider>
      </ToastProvider>
    </>
  );
};

export default App;

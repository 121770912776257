import { AxiosRequestConfig } from "axios";
import { EditUserRequest, UserResponse, UserResquest } from "models";
import service from ".";

export const UserCreate = async (
  params: UserResquest,
  config?: AxiosRequestConfig
) => await service.post<UserResponse>("/user", params, config);

export const UserEdit = async (
  params: EditUserRequest,
  config?: AxiosRequestConfig
) => await service.put("/user", params, config);

export const GetAllUser = async (
  user_id: string,
  config?: AxiosRequestConfig
) =>
  await service.get<UserResponse[]>("/users", {
    ...config,
    params: { user_id }
  });

export const GetUser = async (id: string, config?: AxiosRequestConfig) =>
  await service.get<UserResponse>("/user", {
    ...config,
    params: { id }
  });

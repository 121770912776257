import { AxiosRequestConfig } from "axios";
import { InvoiceResponse } from "models/invoices";
import service from ".";

export const GetInvoices = async (
  user_id: string,
  config?: AxiosRequestConfig
) =>
  await service.get<InvoiceResponse[]>("/invoices", {
    ...config,
    params: { user_id }
  });
export const ReprocessInvoices = async (
  _id: string,
  config?: AxiosRequestConfig
) =>
  await service.post("/invoices/reprocessement", {
    ...config,
    _id
  });

export const GetInvoice = async (
  _id: string,
  user_id: string,
  config?: AxiosRequestConfig
) =>
  await service.get("/invoice", {
    ...config,
    params: { _id, user_id }
  });

export const sendWorkFlowApprove = async (
  _id: string,
  user_id: string,
  config?: AxiosRequestConfig
) =>
  await service.put("/workflow/approve", {
    _id,
    user_id,
    ...config
  });

export const sendWorkFlowRefuse = async (
  _id: string,
  user_id: string,
  message: string,
  config?: AxiosRequestConfig
) =>
  await service.put("/workflow/refuse", {
    _id,
    user_id,
    message,
    ...config
  });

export const sendWorkFlowReview = async (
  _id: string,
  user_id: string,
  message: string,
  config?: AxiosRequestConfig
) =>
  await service.put("/workflow/review", {
    _id,
    user_id,
    message,
    ...config
  });

export const sendWorkFlowCheck = async (
  _id: string,
  user_id: string,
  message: string,
  config?: AxiosRequestConfig
) =>
  await service.put("/workflow/check", {
    _id,
    user_id,
    message,
    ...config
  });

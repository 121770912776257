/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { validPassword, validPhone, validZipCode } from "consts";
import * as yup from "yup";

// Defaults

const OptionalSchema = yup.string().optional();
const NumberSchema = yup.number().required("errors.required");
const RequiredSchema = yup.string().required("errors.required");
const RequiredCodeOrganizationSchema = yup
  .string()
  .min(8, "errors.codeLength")
  .required("errors.required");

const StringArraySchema = yup.array().of(yup.string());

const StringArrayRequiredSchema = StringArraySchema.required(
  "errors.required"
).min(1, "errors.required");
const StringArrayOptionalSchema = StringArraySchema.optional();

// Compounds

const AddressSchema = yup.object().shape({
  zip_code: yup
    .string()
    .matches(validZipCode, {
      message: "errors.invalidZipCode",
      excludeEmptyString: true
    })
    .optional(),
  address: OptionalSchema,
  number: OptionalSchema,
  country: OptionalSchema,
  state: OptionalSchema,
  city: OptionalSchema
});

const EmailRequiredSchema = yup
  .string()
  .required("errors.required")
  .email("errors.invalidEmail");

const PasswordSchema = yup
  .string()
  .required("errors.required")
  .matches(validPassword, "errors.invalidPassword");

const PhoneSchema = yup
  .string()
  .required("errors.required")
  .matches(validPhone, "errors.invalidPhone");

// exports

export const ChangePasswordSchema = yup.object().shape({
  passwordHash: PasswordSchema,
  newPassword: PasswordSchema
});
export const UpdateSchema = yup.object().shape({
  old_password: PasswordSchema,
  password: PasswordSchema,
  password_confirmation: PasswordSchema
});

export const ForgotEmailSchema = yup.object().shape({
  phone: PhoneSchema
});

export const ForgotPasswordSchema = yup.object().shape({
  email: EmailRequiredSchema
});

export const NewPasswordSchema = yup.object().shape({
  password: PasswordSchema,
  password_confirmation: PasswordSchema
});

export const NewUserSchema = yup.object().shape({
  name: RequiredSchema,
  email: EmailRequiredSchema,
  phone: RequiredSchema,
  claim: RequiredSchema,
  organizations: StringArrayRequiredSchema
});

export const EditUserSchema = yup.object().shape({
  name: OptionalSchema,
  current_organization: OptionalSchema
});

export const ProductSchema = yup.object().shape({
  sku: RequiredSchema,
  name: RequiredSchema,
  description: RequiredSchema,
  tags: StringArrayOptionalSchema
});

export const ProjectSchema = yup.object().shape({
  name: RequiredSchema,
  code: RequiredSchema,
  active: RequiredSchema
});
export const ServiceSchema = yup.object().shape({
  name: RequiredSchema,
  code: RequiredSchema,
  active: RequiredSchema,
  code_project: RequiredSchema,
  description: RequiredSchema,
  tags: StringArrayOptionalSchema
});

export const SignInSchema = yup.object().shape({
  email: EmailRequiredSchema,
  password: RequiredSchema
});

export const CreatePasswordSchema = yup.object().shape({
  confirmation_token: RequiredSchema,
  password: PasswordSchema,
  confirmation_password: PasswordSchema,
  email: EmailRequiredSchema
});
export const SignUpSchema = yup.object().shape({
  companyName: RequiredSchema,
  name: RequiredSchema,
  phone: PhoneSchema,
  email: EmailRequiredSchema,
  password: PasswordSchema
});
export const PlansFormSchema = yup.object().shape({
  code_account: OptionalSchema,
  code_project: RequiredSchema,
  code_service: RequiredSchema,
  sku: RequiredSchema,
  reference: RequiredSchema,
  start: RequiredSchema,
  finish: OptionalSchema,
  observation: OptionalSchema,
  plan_type: RequiredSchema,
  value: RequiredSchema,
  tags: StringArrayOptionalSchema
});

const MultiPlansSchema = yup
  .array()
  .of(
    yup.object().shape({
      code_project: RequiredSchema,
      code_service: RequiredSchema,
      sku: RequiredSchema,
      reference: RequiredSchema,
      start: RequiredSchema,
      finish: OptionalSchema,
      plan_type: RequiredSchema,
      value: RequiredSchema,
      tags: OptionalSchema,
      observation: OptionalSchema
    })
  )
  .required("errors.required")
  .min(1, "errors.required");

export const MultiPlansFormSchema = yup.object().shape({
  code_account: OptionalSchema,
  plans: MultiPlansSchema
});

export const RegisterSchema = yup.object().shape({
  genre: OptionalSchema,
  phone: PhoneSchema,
  birthDate: RequiredSchema,
  userAddress: AddressSchema
});

const Adress = yup.object().shape({
  zip_code: yup
    .string()
    .matches(validZipCode, {
      message: "errors.invalidZipCode",
      excludeEmptyString: true
    })
    .optional(),
  address: OptionalSchema,
  complement: OptionalSchema,
  number: OptionalSchema,
  neiborhood: OptionalSchema,
  country: OptionalSchema,
  state: OptionalSchema,
  city: OptionalSchema
});
export const RegisterOrganizationSchema = yup.object().shape({
  code: RequiredCodeOrganizationSchema,
  name: RequiredSchema,
  fantasy_name: RequiredSchema,
  cnpj: RequiredSchema,
  business_unit: RequiredSchema,
  owner_id: OptionalSchema,
  // created_by: RequiredSchema,
  phone: RequiredSchema,
  email: RequiredSchema,
  due_day: OptionalSchema,
  address: Adress,
  tags: OptionalSchema
});
export const RegisterSubOrganizationSchema = yup.object().shape({
  code: RequiredSchema,
  name: RequiredSchema,
  fantasy_name: RequiredSchema,
  cnpj: RequiredSchema,
  owner_id: OptionalSchema,
  phone: RequiredSchema,
  email: RequiredSchema,
  address: Adress,
  tags: OptionalSchema
});

export const CreditSchema = yup.object().shape({
  description: RequiredSchema,
  value: RequiredSchema,
  type: RequiredSchema,
  start_date: RequiredSchema,
  end_date: RequiredSchema
});

const rangesSchema = yup
  .array()
  .of(
    yup.object().shape({
      from: NumberSchema,
      to: NumberSchema,
      value: RequiredSchema,
      range_type: RequiredSchema
    })
  )
  .required("errors.required")
  .min(1, "errors.required")
  .test(
    "interval-not-overlap",
    "Os intervalos não podem se sobrepor",
    function (value) {
      if (!Array.isArray(value) || value.length < 2) {
        return true; // Não há intervalos suficientes para verificar a sobreposição.
      }

      // Classifique os intervalos com base no campo "from".
      const sortedRanges = [...value].sort(
        (a, b) => (a.from ?? 0) - (b.from ?? 0)
      );

      for (let i = 1; i < sortedRanges.length; i++) {
        const currentInterval = sortedRanges[i];
        const previousInterval = sortedRanges[i - 1];

        if (
          (currentInterval.from ?? 0) <= (previousInterval.to ?? 0) ||
          (currentInterval.to ?? 0) <= (previousInterval.to ?? 0)
        ) {
          return false; // Intervalos se sobrepõem.
        }
      }

      return true; // Não há sobreposição de intervalos.
    }
  );

export const OnDemandServiceSchema = yup.object().shape({
  code_account: OptionalSchema,
  code_project: RequiredSchema,
  code_service: RequiredSchema,
  reference: RequiredSchema,
  start: RequiredSchema,
  finish: OptionalSchema,
  product: RequiredSchema,
  last_from: RequiredSchema,
  last_value_from: RequiredSchema,
  tags: OptionalSchema,
  ranges: rangesSchema
});

export const ImportSchema = yup.object().shape({
  code_account: OptionalSchema,
  name: OptionalSchema,
  reference: RequiredSchema
});

export const ExportRpsSchema = yup.object().shape({
  reference: RequiredSchema
});

const floatItens = yup
  .array()
  .of(
    yup.object().shape({
      code_service: RequiredSchema,
      product: RequiredSchema,
      excess_value: RequiredSchema
    })
  )
  .required("errors.required")
  .min(1, "errors.required");

export const PackageSchema = yup.object().shape({
  name: RequiredSchema,
  code_account: OptionalSchema,
  code_project: RequiredSchema,
  package_type: RequiredSchema,
  reference: RequiredSchema,
  quantity: OptionalSchema,
  value: RequiredSchema,
  start: RequiredSchema,
  finish: OptionalSchema,
  tags: OptionalSchema,
  floatItens: floatItens
});

const productSchema = yup
  .array()
  .of(
    yup.object().shape({
      excess_value: RequiredSchema,
      product: RequiredSchema
    })
  )
  .required("errors.required")
  .min(1, "errors.required");

export const PakageOnDemandSchema = yup.object().shape({
  code_account: OptionalSchema,
  name: RequiredSchema,
  package_type: RequiredSchema,
  products: productSchema,
  reference: RequiredSchema,
  start: RequiredSchema,
  finish: OptionalSchema,
  tags: StringArrayOptionalSchema,
  ranges: rangesSchema,
  last_from: RequiredSchema,
  last_value_from: RequiredSchema
});

import { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import Tag from "../tag";
import {
  CardInfoStylesType,
  Container,
  Footer,
  HasNotification,
  Header,
  IconContainer,
  Main
} from "./styles";

type CardInfoType = CardInfoStylesType & {
  icon?: ReactNode;
  status?: boolean;
  title: string;
  text?: string;
  button?: ReactNode;
};

const CardInfo = ({ icon, status, title, text, ...args }: CardInfoType) => {
  return (
    <Container {...args}>
      <Header>
        <IconContainer>{icon}</IconContainer>
      </Header>
      <Main {...args}>
        <p>{title}</p>
        {text && <span>{text}</span>}
      </Main>
      {!status && <HasNotification />}
    </Container>
  );
};

export default CardInfo;

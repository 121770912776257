/* eslint-disabled react/no-children-prop */
import Layout from "components/layout";
import FinanceProvider from "providers/fiance";
import { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import Loading from "../components/loading";
import RequireAuth from "./requireAuth";

const AuthPage = lazy(() => import("pages/auth"));
const OrganizationsPage = lazy(() => import("pages/orgs"));
const UsersPage = lazy(() => import("pages/users"));
const DashboardPage = lazy(() => import("pages/dashboard"));
const ServicesPage = lazy(() => import("pages/services"));
const FinancePage = lazy(() => import("pages/finance"));
const InvoiceImport = lazy(() => import("pages/invoiceImport"));
const InvoiceExport = lazy(() => import("pages/exportInvoice"));
const SlugFatura = lazy(
  () => import("pages/finance/subpages/invoiceList/slugFatura")
);
const ProfilePage = lazy(() => import("pages/profile"));
const AuditLogsPage = lazy(() => import("pages/auditLogs"));

const RoutesMap = () => {
  return (
    <Suspense fallback={<Loading />}>
      <Routes>
        <Route path="sign-in/*" element={<AuthPage />} />

        <Route path="/" element={<RequireAuth children={<Layout />} />}>
          <Route index element={<DashboardPage />} />
          <Route path="organizations/*" element={<OrganizationsPage />} />

          <Route path="profile">
            <Route index element={<ProfilePage />} />
            <Route path=":step" element={<ProfilePage />} />
          </Route>
          <Route path="users">
            <Route index element={<UsersPage />} />
            <Route path=":step" element={<UsersPage />} />
          </Route>
          <Route path="audit-logs">
            <Route index element={<AuditLogsPage />} />
            <Route path=":step" element={<AuditLogsPage />} />
          </Route>
          <Route path="finance">
            <Route index element={<FinancePage />} />
            <Route path=":step" element={<FinancePage />} />
            <Route
              path="invoice/:invoice_id"
              element={
                <FinanceProvider>
                  <SlugFatura />
                </FinanceProvider>
              }
            />
          </Route>
          <Route path="services">
            <Route index element={<ServicesPage />} />
            <Route path=":step" element={<ServicesPage />} />
          </Route>
          <Route path="invoice-import">
            <Route index element={<InvoiceImport />} />
            <Route path=":step" element={<InvoiceImport />} />
          </Route>
          <Route path="invoice-export">
            <Route index element={<InvoiceExport />} />
            <Route path=":step" element={<InvoiceExport />} />
          </Route>
        </Route>
      </Routes>
    </Suspense>
  );
};

export default RoutesMap;

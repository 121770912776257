/* eslint-disabled prettier/prettier */
import { Badge, Container, NotifyButton } from "./styles";
import { ReactComponent as NotificationICon } from "assets/icons/notifyIcon.svg";
import NotificationsItems from "./notificationsItems";
import NotificationViewModel from "./viewModel";
import CardInfo from "components/cardInfo";
import { FormatDate } from "utils";
const Notifications = () => {
  const viewModel = NotificationViewModel();

  const { notificationsList, openNotifications, ref, countNotifications } =
    viewModel;
  const { t, setOpenNoitifications, handleReadNotification } = viewModel;
  return (
    <Container ref={ref}>
      <NotifyButton
        onClick={() => {
          setOpenNoitifications(!openNotifications);
        }}>
        <NotificationICon />
        {countNotifications.length > 0 && (
          <Badge>
            {countNotifications?.length >= 100
              ? "+99"
              : countNotifications?.length}
          </Badge>
        )}
      </NotifyButton>
      {openNotifications && (
        <NotificationsItems>
          {notificationsList.length ? (
            notificationsList?.map(notification => (
              <CardInfo
                key={notification?._id}
                onClick={() => {
                  handleReadNotification(notification);
                  setOpenNoitifications(!openNotifications);
                }}
                fullWidth
                icon={<NotificationICon />}
                title={notification?.message}
                text={FormatDate(notification?.created_at, "	PP")}
                status={notification?.read}
              />
            ))
          ) : (
            <CardInfo
              fullWidth
              icon={<NotificationICon />}
              title={t("notifications.noHasNotification")}
              text={t("notifications.waitNotification")}
              status={true}
            />
          )}
        </NotificationsItems>
      )}
    </Container>
  );
};

export default Notifications;

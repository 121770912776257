/* eslint-disable prettier/prettier */
import { ReactComponent as ArrowIcon } from "assets/icons/arrowDown.svg";
import styled, { css } from "styled-components";
import Input from "components/input";

export type SelectStylesType = {
  fullWidth?: boolean;
  open?: boolean;
  disable?: boolean;
  direction?: "up" | "down";
};

// export type SlectOptionStyle = {
//   disable?: boolean;
// };

export const Container = styled.div<SelectStylesType>`
  width: ${({ fullWidth }) => (fullWidth ? "100%" : "max-content")};
  position: relative;
`;

export const SelectContent = styled.div<SelectStylesType>`
  width: 100%;
  height: 4rem;
  border-radius: 0.4rem;
  border: 0.1rem solid var(--neutral-400);
  padding: 1rem 1.2rem;
  font-size: 1.4rem;
  font-weight: 400;
  background: ${({ disable }) => (disable ? "var(--neutral)" : "transparent")};

  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  gap: 1.2rem;

  cursor: pointer;

  span {
    color: var(--neutral-400);
  }

  p {
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const ArrowDownIcon = styled(ArrowIcon) <SelectStylesType>`
  transition: 250ms ease-in-out;

  ${({ open }) =>
    open &&
    css`
      transform: rotate(180deg);
    `}
`;

export const OptionsContent = styled.div<SelectStylesType>`
  width: 100%;
  max-height: 25rem;
  overflow: hidden auto;
  position: absolute;
  left: 0;
  right: 0;
  border-radius: 0.4rem;
  background-color: var(--white);
  border: 0.1rem solid var(--neutral-400);
  padding: 0.8rem;
  z-index: 5;

  display: none;

  ${({ direction = "down" }) =>
    direction === "down"
      ? css`
          bottom: -0.4rem;
          transform: translateY(100%);
        `
      : css`
          top: -0.4rem;
          transform: translateY(-100%);
        `}

  ${({ open }) =>
    open &&
    css`
      min-width: 30.2rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 0.4rem;
    `}
`;

export const NoOptionsContent = styled.div`
  padding: 2rem;
`;

export const ContainerOptions = styled.div<SelectStylesType>`
  width: 100%;
  padding: 0.8rem;
  font-size: 1.4rem;
  line-height: 2rem;
  border-radius: 0.4rem;
  background: ${({ disable }) => (disable ? "var(--neutral)" : "transparent")};

  &:hover,
  &:focus {
    background-color: ${({ disable }) =>
    !disable && "var(--blue-dark-800)"};
    color: ${({ disable }) =>
    !disable && "var(--white)"};
    cursor: ${({ disable }) => (disable ? "none" : "pointer")};
    pointer-events: ${({ disable }) => (disable ? "none" : "visible")};
  }
`;

export const MultiSelectSearch = styled(Input).attrs({ type: "search" })`
  width: 100%;
  margin-bottom: 0.8rem;

  > div {
    border: none;
    background: var(--neutral);
  }
`;

import styled from "styled-components";
export interface CardInfoStylesType {
  fullWidth?: boolean;
  fullWHeight?: boolean;
  fullText?: boolean;
  onClick?: () => void;
}

export const Container = styled.div<CardInfoStylesType>`
  min-width: 22.6rem;
  height: ${({ fullWHeight }) => (fullWHeight ? "100%" : "auto")};
  width: ${({ fullWidth }) => (fullWidth ? "100%" : "auto")};
  padding: 1.6rem;
  /* margin-top: 0.5; */
  border-radius: 0.2rem;
  border-bottom: 1px solid var(--gray);
  display: flex;
  align-items: center;
  /* flex-direction: column; */
  cursor: ${({ onClick }) => (onClick ? "pointer" : "inintial")};
  gap: 1.6rem;
  :hover {
    background: #dddddd;
  }
`;

export const IconContainer = styled.div`
  width: 2.6rem;
  height: 2.6rem;
  display: flex;
  padding: 1rem;
  justify-content: center;
  align-items: center;
  border-radius: 0.5rem;
  background-color: var(--blue-dark-600);
  color: var(--white);
`;

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

export const Main = styled.main<CardInfoStylesType>`
  min-height: 4.4rem;
  text-align: left;

  p {
    font-weight: bold;
    width: ${({ fullText }) => (fullText ? "100%" : " 24.8rem")};
    color: var(--text-title);
    font-size: 1.6rem;
    line-height: 2.4rem;
  }

  span {
    font-size: 1.4rem;
    line-height: 2rem;
  }
`;

export const Footer = styled.footer`
  width: 100%;
  margin-top: 2rem;
`;

export const HasNotification = styled.span`
  width: 1.6rem;
  height: 1.6rem;
  background-color: var(--blue-dark-600);
  border-radius: 50%;
`;

import { add, format, sub } from "date-fns";
import { ptBR } from "date-fns/locale";

export const FormatDate = (date: Date, dateFormat: string) => {
  const currentDate = new Date(date);
  const offsetInMinutes = currentDate.getTimezoneOffset();
  let formattedDate = "";
  if (offsetInMinutes >= 0) {
    formattedDate = format(
      add(currentDate, { minutes: offsetInMinutes }),
      dateFormat,
      { locale: ptBR }
    );
  }
  if (offsetInMinutes <= 0) {
    formattedDate = format(
      sub(currentDate, { minutes: offsetInMinutes }),
      dateFormat,
      { locale: ptBR }
    );
  }
  return formattedDate;
};

import useMap from "hooks/map";
import {
  Children,
  cloneElement,
  isValidElement,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { exitElement, removeAccents } from "utils";
import Option, { NoOptions, OptionType, ValueType } from "./option";
import {
  ArrowDownIcon,
  Container,
  MultiSelectSearch,
  OptionsContent,
  SelectContent,
  SelectStylesType
} from "./styles";
import { useTranslation } from "react-i18next";

type SelectType = Omit<SelectStylesType, "open"> & {
  children: ReactNode;
  placeholder?: string;
  defaultValue?: ValueType;
  value?: ValueType;
  onChange?: (value: ValueType) => void;
  hasSearch?: boolean;
};

const NavBarSelect = ({
  children,
  placeholder,
  defaultValue = "",
  value,
  direction,
  onChange,
  disable,
  hasSearch = true,
  ...args
}: SelectType) => {
  const [open, setOpen] = useState(false);
  const [internalValue, setInternalValue] = useState<ValueType>(defaultValue);
  const [texts, { set }] = useMap<ValueType, ReactNode>();
  const [search, setSearch] = useState("");

  const { t } = useTranslation("components");

  const ref = useRef<HTMLDivElement>(null);

  const currentValue = useMemo(
    () => (value !== undefined ? value : internalValue),
    [value, internalValue]
  );

  const includesFilter = useCallback(
    (value: string) => {
      if (!hasSearch) return true;
      else if (!search) return true;
      else
        return value
          .toLocaleLowerCase()
          .includes(removeAccents(search).toLocaleLowerCase());
    },
    [hasSearch, search]
  );

  useEffect(() => {
    const currentTexts = new Map();
    Children.forEach(children, child => {
      if (isValidElement<OptionType>(child)) {
        currentTexts.set(child.props?.value, child.props?.children);
      }
    });
    set(currentTexts);
  }, [children, set]);

  useEffect(() => {
    const hide = exitElement(ref, () => setOpen(false));
    document.documentElement.addEventListener("click", hide);
    return () => document.documentElement.removeEventListener("click", hide);
  }, []);

  const handleSelect = useCallback(
    (valueOption: ValueType) => {
      onChange?.(valueOption);
      value === undefined && setInternalValue(valueOption);
      setOpen(false);
      setSearch("");
    },
    [value, onChange]
  );

  return (
    <Container ref={ref} {...args}>
      <SelectContent
        disable={disable}
        onClick={() => !disable && setOpen(!open)}>
        <ArrowDownIcon open={open} />
        {currentValue ? (
          <p>{texts?.get(currentValue)}</p>
        ) : (
          <span>{placeholder}</span>
        )}
      </SelectContent>
      <OptionsContent open={open} direction={direction}>
        <>
          {hasSearch && (
            <MultiSelectSearch
              placeholder={t("search")}
              value={search}
              onChange={({ target }) => setSearch(target.value)}
            />
          )}

          {Children.count(children) ? (
            Children.map(children, child => {
              if (
                isValidElement<OptionType>(child) &&
                includesFilter(removeAccents(child.props.children as string))
              ) {
                return cloneElement(child, { onClick: handleSelect });
              }
            })
          ) : (
            <NoOptions />
          )}
        </>
      </OptionsContent>
    </Container>
  );
};
export { Option };
export type { ValueType };
export default NavBarSelect;

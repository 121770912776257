import { validCNPJ } from "consts";
// import { ItemFlowType, JourneyType } from "models";

export const validateCNPJ = (value?: string) => {
  if (value && validCNPJ.test(value)) {
    const currentValue = (value.match(/\d/g) || []).map(Number);
    const digits = currentValue?.slice(12);

    if (new Set(currentValue).size === 1) return false;

    const calc = (x: number) => {
      const slice = currentValue.slice(0, x);
      let factor = x - 7;
      let sum = 0;

      for (let i = x; i >= 1; i--) {
        const n = slice[x - i];
        sum += n * factor--;
        if (factor < 2) factor = 9;
      }

      const result = 11 - (sum % 11);

      return result > 9 ? 0 : result;
    };

    const first = calc(12);
    if (first !== digits[0]) return false;

    const second = calc(13);
    return second === digits[1];
  }

  return false;
};

export const validateDate = (date: string) => {
  return !isNaN(Date.parse(date));
};

export const isValidField = (value: unknown) => {
  if (value === undefined || value === null) return false;
  if (typeof value === "string" && value.trim() === "") return false;
  if (typeof value === "boolean") return true;

  return true;
};

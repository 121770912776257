import { useAuth } from "providers/auth";
import { Navigate, useLocation } from "react-router-dom";
type RequireAuthType = { children: JSX.Element };

const RequireAuth = ({ children }: RequireAuthType) => {
  // const [redirect, setRedirect] = useState(false);
  const { isAuthenticated } = useAuth();
  const location = useLocation();

  if (!isAuthenticated) {
    return <Navigate to="/sign-in" state={{ from: location }} replace />;
  }

  return children;
};

export default RequireAuth;

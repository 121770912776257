import useMap from "hooks/map";
import {
  ClaimRequest,
  EditUserRequest,
  UserResponse,
  UserResponseMap,
  UserResquest
} from "models";
import { UersErrors } from "pages/users/errors";
import { useAuth } from "providers/auth";
import { useToast } from "providers/toast";
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from "react";
import { useTranslation } from "react-i18next";
import * as ClaimService from "services/claim";
import * as UsersService from "services/user";

type UserContextType = {
  currentUser?: UserResponse;
  users?: Map<string, UserResponseMap>;
  isDisabled?: boolean;
  loading?: boolean;
  initLoading?: boolean;
  claims?: ClaimRequest[];
  getUser: (id: string) => void;

  addUser: (value: UserResponse) => void;
  editUser: (id: string, value: EditUserRequest) => void;
  blockedUser?: (key: string) => void;
};

type UserProviderType = {
  children: ReactNode;
};

const UserContext = createContext({} as UserContextType);

const UserProvider = ({ children }: UserProviderType) => {
  const [users, { set, add, edit }] = useMap<string, UserResponseMap>();
  const [currentUser, setCurrentUser] = useState<UserResponse>();
  const [loading, setLoading] = useState(false);
  const [initLoading, setInitLoading] = useState(false);
  const { t } = useTranslation("setting");
  const { error, warning, success } = useToast();
  const { tokenDecoded } = useAuth();
  const [claims, setClaims] = useState<ClaimRequest[]>();

  const handleSuccess = useCallback(
    (isEdit: boolean) => {
      success({
        description: t(`alerts.${isEdit ? "updatedUser" : "createdUser"}`)
      });
    },
    [success, t]
  );
  const handleWarning = useCallback(() => {
    warning({
      description: t(`warningAlert`)
    });
  }, [warning, t]);

  const errorsResolver = useMemo(
    () => new UersErrors({ error, warning }, t),
    [error, warning, t]
  );

  const getClaim = useCallback(async () => {
    try {
      await ClaimService.GetClaim().then(({ data }) => {
        setClaims(data);
      });
    } catch (error) {
      () => errorsResolver.defaultError;
    }
  }, [errorsResolver]);

  const getAllUser = useCallback(async () => {
    setLoading(true);
    if (!currentUser) return;

    await UsersService.GetAllUser(currentUser?._id).then(({ data }) => {
      set(new Map(data.map(user => [user._id, user])));
    });
    setLoading(false);
  }, [currentUser, set]);

  const getUser = useCallback(async (id: string) => {
    setLoading(true);
    await UsersService.GetUser(id).then(({ data }) => {
      setCurrentUser(data);
    });
    setLoading(false);
  }, []);

  const getUserInit = useCallback(
    async (id: string) => {
      setInitLoading(true);
      await UsersService.GetUser(id)
        .then(({ data }) => {
          setCurrentUser(data);
        })
        .catch(errorsResolver.defaultError);
      setInitLoading(false);
    },
    [errorsResolver]
  );

  const addUser = useCallback(
    async ({ ...user }) => {
      await UsersService.UserCreate(user as UserResquest)
        .then(({ data }) => {
          add(data?._id, data as UserResponse);
          handleSuccess(false);
        })
        .catch(errorsResolver.defaultError);
    },
    [errorsResolver, handleSuccess, add]
  );

  const editUser = useCallback(
    async (_id: string, user: EditUserRequest) => {
      setLoading(true);
      handleWarning();
      await UsersService.UserEdit(user)
        .then(({ data }) => {
          const currentUse = users?.get(_id);
          const currentClaim = claims?.find(c => c._id === data?.claim);
          getUser(currentUser?._id as string);
          if (currentUse)
            edit(_id, {
              ...currentUse,
              phone: data?.phone ?? currentUse?.phone,
              name: data?.name ?? currentUse?.name,
              organizations: data?.organizations ?? currentUse?.organizations,
              claim: currentClaim ?? currentUse?.claim,
              current_organization:
                data?.current_organization ?? currentUse?.current_organization
            });
          handleSuccess(true);
        })
        .catch(error => {
          errorsResolver.defaultError(error);
          getUser(currentUser?._id as string);
        });
      setLoading(false);
    },
    [
      errorsResolver,
      users,
      currentUser,
      claims,
      getUser,
      handleSuccess,
      handleWarning,
      edit
    ]
  );

  const isDisabled = useMemo(() => {
    const userType = currentUser?.claim?.type;
    const userClaim = currentUser?.claim?.title;
    const CodeHabilit = ["root", "admin", "cs", "insade sales", "channels"];
    switch (userType) {
      case "ligo":
        if (CodeHabilit.includes(`${userClaim}`)) {
          return false;
        }
        return true;
      case "client":
        if (userClaim === "admin") {
          return false;
        }
        return true;
    }
    return true;
  }, [currentUser]);
  useEffect(() => {
    getClaim();
  }, [getClaim]);

  useEffect(() => {
    getAllUser();
  }, [getAllUser]);

  useEffect(() => {
    if (!tokenDecoded?.userId) return;
    getUserInit(tokenDecoded?.userId);
  }, [tokenDecoded?.userId, getUserInit]);

  return (
    <UserContext.Provider
      value={{
        users,
        currentUser,
        loading,
        isDisabled,
        claims,
        initLoading,
        getUser,
        addUser,
        editUser
      }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);

export default UserProvider;

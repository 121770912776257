import { OptionType } from "models";

export const countries: OptionType<string>[] = [
  { value: "AF", label: "Afghanistan" },
  { value: "AX", label: "Aland Islands" },
  { value: "AL", label: "Albania" },
  { value: "DZ", label: "Algeria" },
  { value: "AS", label: "American Samoa" },
  { value: "AD", label: "Andorra" },
  { value: "AO", label: "Angola" },
  { value: "AI", label: "Anguilla" },
  { value: "AQ", label: "Antarctica" },
  { value: "AG", label: "Antigua and Barbuda" },
  { value: "AR", label: "Argentina" },
  { value: "AM", label: "Armenia" },
  { value: "AW", label: "Aruba" },
  { value: "AU", label: "Australia" },
  { value: "AT", label: "Austria" },
  { value: "AZ", label: "Azerbaijan" },
  { value: "BS", label: "Bahamas" },
  { value: "BH", label: "Bahrain" },
  { value: "BD", label: "Bangladesh" },
  { value: "BB", label: "Barbados" },
  { value: "BY", label: "Belarus" },
  { value: "BE", label: "Belgium" },
  { value: "BZ", label: "Belize" },
  { value: "BJ", label: "Benin" },
  { value: "BM", label: "Bermuda" },
  { value: "BT", label: "Bhutan" },
  { value: "BO", label: "Bolivia" },
  { value: "BQ", label: "Bonaire, Saint Eustatius and Saba " },
  { value: "BA", label: "Bosnia and Herzegovina" },
  { value: "BW", label: "Botswana" },
  { value: "BV", label: "Bouvet Island" },
  { value: "BR", label: "Brazil" },
  { value: "IO", label: "British Indian Ocean Territory" },
  { value: "VG", label: "British Virgin Islands" },
  { value: "BN", label: "Brunei" },
  { value: "BG", label: "Bulgaria" },
  { value: "BF", label: "Burkina Faso" },
  { value: "BI", label: "Burundi" },
  { value: "KH", label: "Cambodia" },
  { value: "CM", label: "Cameroon" },
  { value: "CA", label: "Canada" },
  { value: "CV", label: "Cape Verde" },
  { value: "KY", label: "Cayman Islands" },
  { value: "CF", label: "Central African Republic" },
  { value: "TD", label: "Chad" },
  { value: "CL", label: "Chile" },
  { value: "CN", label: "China" },
  { value: "CX", label: "Christmas Island" },
  { value: "CC", label: "Cocos Islands" },
  { value: "CO", label: "Colombia" },
  { value: "KM", label: "Comoros" },
  { value: "CK", label: "Cook Islands" },
  { value: "CR", label: "Costa Rica" },
  { value: "HR", label: "Croatia" },
  { value: "CU", label: "Cuba" },
  { value: "CW", label: "Curacao" },
  { value: "CY", label: "Cyprus" },
  { value: "CZ", label: "Czech Republic" },
  { value: "CD", label: "Democratic Republic of the Congo" },
  { value: "DK", label: "Denmark" },
  { value: "DJ", label: "Djibouti" },
  { value: "DM", label: "Dominica" },
  { value: "DO", label: "Dominican Republic" },
  { value: "TL", label: "East Timor" },
  { value: "EC", label: "Ecuador" },
  { value: "EG", label: "Egypt" },
  { value: "SV", label: "El Salvador" },
  { value: "GQ", label: "Equatorial Guinea" },
  { value: "ER", label: "Eritrea" },
  { value: "EE", label: "Estonia" },
  { value: "ET", label: "Ethiopia" },
  { value: "FK", label: "Falkland Islands" },
  { value: "FO", label: "Faroe Islands" },
  { value: "FJ", label: "Fiji" },
  { value: "FI", label: "Finland" },
  { value: "FR", label: "France" },
  { value: "GF", label: "French Guiana" },
  { value: "PF", label: "French Polynesia" },
  { value: "TF", label: "French Southern Territories" },
  { value: "GA", label: "Gabon" },
  { value: "GM", label: "Gambia" },
  { value: "GE", label: "Georgia" },
  { value: "DE", label: "Germany" },
  { value: "GH", label: "Ghana" },
  { value: "GI", label: "Gibraltar" },
  { value: "GR", label: "Greece" },
  { value: "GL", label: "Greenland" },
  { value: "GD", label: "Grenada" },
  { value: "GP", label: "Guadeloupe" },
  { value: "GU", label: "Guam" },
  { value: "GT", label: "Guatemala" },
  { value: "GG", label: "Guernsey" },
  { value: "GN", label: "Guinea" },
  { value: "GW", label: "Guinea-Bissau" },
  { value: "GY", label: "Guyana" },
  { value: "HT", label: "Haiti" },
  { value: "HM", label: "Heard Island and McDonald Islands" },
  { value: "HN", label: "Honduras" },
  { value: "HK", label: "Hong Kong" },
  { value: "HU", label: "Hungary" },
  { value: "IS", label: "Iceland" },
  { value: "IN", label: "India" },
  { value: "ID", label: "Indonesia" },
  { value: "IR", label: "Iran" },
  { value: "IQ", label: "Iraq" },
  { value: "IE", label: "Ireland" },
  { value: "IM", label: "Isle of Man" },
  { value: "IL", label: "Israel" },
  { value: "IT", label: "Italy" },
  { value: "CI", label: "Ivory Coast" },
  { value: "JM", label: "Jamaica" },
  { value: "JP", label: "Japan" },
  { value: "JE", label: "Jersey" },
  { value: "JO", label: "Jordan" },
  { value: "KZ", label: "Kazakhstan" },
  { value: "KE", label: "Kenya" },
  { value: "KI", label: "Kiribati" },
  { value: "XK", label: "Kosovo" },
  { value: "KW", label: "Kuwait" },
  { value: "KG", label: "Kyrgyzstan" },
  { value: "LA", label: "Laos" },
  { value: "LV", label: "Latvia" },
  { value: "LB", label: "Lebanon" },
  { value: "LS", label: "Lesotho" },
  { value: "LR", label: "Liberia" },
  { value: "LY", label: "Libya" },
  { value: "LI", label: "Liechtenstein" },
  { value: "LT", label: "Lithuania" },
  { value: "LU", label: "Luxembourg" },
  { value: "MO", label: "Macao" },
  { value: "MK", label: "Macedonia" },
  { value: "MG", label: "Madagascar" },
  { value: "MW", label: "Malawi" },
  { value: "MY", label: "Malaysia" },
  { value: "MV", label: "Maldives" },
  { value: "ML", label: "Mali" },
  { value: "MT", label: "Malta" },
  { value: "MH", label: "Marshall Islands" },
  { value: "MQ", label: "Martinique" },
  { value: "MR", label: "Mauritania" },
  { value: "MU", label: "Mauritius" },
  { value: "YT", label: "Mayotte" },
  { value: "MX", label: "Mexico" },
  { value: "FM", label: "Micronesia" },
  { value: "MD", label: "Moldova" },
  { value: "MC", label: "Monaco" },
  { value: "MN", label: "Mongolia" },
  { value: "ME", label: "Montenegro" },
  { value: "MS", label: "Montserrat" },
  { value: "MA", label: "Morocco" },
  { value: "MZ", label: "Mozambique" },
  { value: "MM", label: "Myanmar" },
  { value: "NA", label: "Namibia" },
  { value: "NR", label: "Nauru" },
  { value: "NP", label: "Nepal" },
  { value: "NL", label: "Netherlands" },
  { value: "NC", label: "New Caledonia" },
  { value: "NZ", label: "New Zealand" },
  { value: "NI", label: "Nicaragua" },
  { value: "NE", label: "Niger" },
  { value: "NG", label: "Nigeria" },
  { value: "NU", label: "Niue" },
  { value: "NF", label: "Norfolk Island" },
  { value: "KP", label: "North Korea" },
  { value: "MP", label: "Northern Mariana Islands" },
  { value: "NO", label: "Norway" },
  { value: "OM", label: "Oman" },
  { value: "PK", label: "Pakistan" },
  { value: "PW", label: "Palau" },
  { value: "PS", label: "Palestinian Territory" },
  { value: "PA", label: "Panama" },
  { value: "PG", label: "Papua New Guinea" },
  { value: "PY", label: "Paraguay" },
  { value: "PE", label: "Peru" },
  { value: "PH", label: "Philippines" },
  { value: "PN", label: "Pitcairn" },
  { value: "PL", label: "Poland" },
  { value: "PT", label: "Portugal" },
  { value: "PR", label: "Puerto Rico" },
  { value: "QA", label: "Qatar" },
  { value: "CG", label: "Republic of the Congo" },
  { value: "RE", label: "Reunion" },
  { value: "RO", label: "Romania" },
  { value: "RU", label: "Russia" },
  { value: "RW", label: "Rwanda" },
  { value: "BL", label: "Saint Barthelemy" },
  { value: "SH", label: "Saint Helena" },
  { value: "KN", label: "Saint Kitts and Nevis" },
  { value: "LC", label: "Saint Lucia" },
  { value: "MF", label: "Saint Martin" },
  { value: "PM", label: "Saint Pierre and Miquelon" },
  { value: "VC", label: "Saint Vincent and the Grenadines" },
  { value: "WS", label: "Samoa" },
  { value: "SM", label: "San Marino" },
  { value: "ST", label: "Sao Tome and Principe" },
  { value: "SA", label: "Saudi Arabia" },
  { value: "SN", label: "Senegal" },
  { value: "RS", label: "Serbia" },
  { value: "SC", label: "Seychelles" },
  { value: "SL", label: "Sierra Leone" },
  { value: "SG", label: "Singapore" },
  { value: "SX", label: "Sint Maarten" },
  { value: "SK", label: "Slovakia" },
  { value: "SI", label: "Slovenia" },
  { value: "SB", label: "Solomon Islands" },
  { value: "SO", label: "Somalia" },
  { value: "ZA", label: "South Africa" },
  { value: "GS", label: "South Georgia and the South Sandwich Islands" },
  { value: "KR", label: "South Korea" },
  { value: "SS", label: "South Sudan" },
  { value: "ES", label: "Spain" },
  { value: "LK", label: "Sri Lanka" },
  { value: "SD", label: "Sudan" },
  { value: "SR", label: "Suriname" },
  { value: "SJ", label: "Svalbard and Jan Mayen" },
  { value: "SZ", label: "Swaziland" },
  { value: "SE", label: "Sweden" },
  { value: "CH", label: "Switzerland" },
  { value: "SY", label: "Syria" },
  { value: "TW", label: "Taiwan" },
  { value: "TJ", label: "Tajikistan" },
  { value: "TZ", label: "Tanzania" },
  { value: "TH", label: "Thailand" },
  { value: "TG", label: "Togo" },
  { value: "TK", label: "Tokelau" },
  { value: "TO", label: "Tonga" },
  { value: "TT", label: "Trinidad and Tobago" },
  { value: "TN", label: "Tunisia" },
  { value: "TR", label: "Turkey" },
  { value: "TM", label: "Turkmenistan" },
  { value: "TC", label: "Turks and Caicos Islands" },
  { value: "TV", label: "Tuvalu" },
  { value: "VI", label: "U.S. Virgin Islands" },
  { value: "UG", label: "Uganda" },
  { value: "UA", label: "Ukraine" },
  { value: "AE", label: "United Arab Emirates" },
  { value: "GB", label: "United Kingdom" },
  { value: "US", label: "United States" },
  { value: "UM", label: "United States Minor Outlying Islands" },
  { value: "UY", label: "Uruguay" },
  { value: "UZ", label: "Uzbekistan" },
  { value: "VU", label: "Vanuatu" },
  { value: "VA", label: "Vatican" },
  { value: "VE", label: "Venezuela" },
  { value: "VN", label: "Vietnam" },
  { value: "WF", label: "Wallis and Futuna" },
  { value: "EH", label: "Western Sahara" },
  { value: "YE", label: "Yemen" },
  { value: "ZM", label: "Zambia" },
  { value: "ZW", label: "Zimbabwe" }
];

export const states = new Map<string, OptionType<string>[]>([
  [
    "AR",
    [
      { value: "AR-1", label: "Ciudad Autónoma de Buenos Aires" },
      { value: "AR-2", label: "Buenos Aires" },
      { value: "AR-3", label: "Catamarca" },
      { value: "AR-4", label: "Chaco" },
      { value: "AR-5", label: "Chubut" },
      { value: "AR-6", label: "Córdoba" },
      { value: "AR-7", label: "Corrientes" },
      { value: "AR-8", label: "Entre Ríos" },
      { value: "AR-9", label: "Formosa" },
      { value: "AR-10", label: "Jujuy" },
      { value: "AR-11", label: "La Pampa" },
      { value: "AR-12", label: "La Rioja" },
      { value: "AR-13", label: "Mendoza" },
      { value: "AR-14", label: "Misiones" },
      { value: "AR-15", label: "Neuquén" },
      { value: "AR-16", label: "Río Negro" },
      { value: "AR-17", label: "Salta" },
      { value: "AR-18", label: "San Juan" },
      { value: "AR-19", label: "San Luis" },
      { value: "AR-20", label: "Santa Cruz" },
      { value: "AR-21", label: "Santa Fe" },
      { value: "AR-22", label: "Santiago del Estero" },
      {
        value: "AR-23",
        label: "Tierra del Fuego, Antártida e Islas del Atlántico Sur"
      },
      { value: "AR-24", label: "Tucumán" }
    ]
  ],
  [
    "AT",
    [
      { value: "B", label: "Burgenland" },
      { value: "K", label: "Kärnten" },
      { value: "NÖ", label: "Niederösterreich" },
      { value: "OÖ", label: "Oberösterreich" },
      { value: "S", label: "Salzburg" },
      { value: "ST", label: "Steiermark" },
      { value: "T", label: "Tirol" },
      { value: "V", label: "Vorarlberg" },
      { value: "W", label: "Wien" }
    ]
  ],
  [
    "AU",
    [
      { value: "ACI", label: "Ashmore and Cartier Islands" },
      { value: "AAT", label: "Australian Antarctic Territory" },
      { value: "ACT", label: "Australian Capital Territory" },
      { value: "CX", label: "Christmas Island" },
      { value: "CC", label: "Cocos Islands" },
      { value: "CSI", label: "Coral Sea Islands" },
      { value: "HM", label: "Heard Island and McDonald Islands" },
      { value: "JBT", label: "Jervis Bay Territory" },
      { value: "NSW", label: "New South Wales" },
      { value: "NF", label: "Norfolk Island" },
      { value: "NT", label: "Northern Territory" },
      { value: "QLD", label: "Queensland" },
      { value: "SA", label: "South Australia" },
      { value: "TAS", label: "Tasmania" },
      { value: "VIC", label: "Victoria" },
      { value: "WA", label: "Western Australia" }
    ]
  ],
  [
    "BD",
    [
      { value: "BD-1", label: "Barguna" },
      { value: "BD-2", label: "Barisal" },
      { value: "BD-3", label: "Bhola" },
      { value: "BD-4", label: "Jhalokati" },
      { value: "BD-5", label: "Patuakhali" },
      { value: "BD-6", label: "Pirojpur" },
      { value: "BD-7", label: "Bandarban" },
      { value: "BD-8", label: "Brahmanbaria" },
      { value: "BD-9", label: "Chandpur" },
      { value: "BD-10", label: "Chittagong" },
      { value: "BD-11", label: "Comilla" },
      { value: "BD-12", label: "Cox' Bazar" },
      { value: "BD-13", label: "Feni" },
      { value: "BD-14", label: "Khagrachhari" },
      { value: "BD-15", label: "Lakshmipur" },
      { value: "BD-16", label: "Noakhali" },
      { value: "BD-17", label: "Rangamati" },
      { value: "BD-18", label: "Dhaka" },
      { value: "BD-19", label: "Faridpur" },
      { value: "BD-20", label: "Gazipur" },
      { value: "BD-21", label: "Gopalganj" },
      { value: "BD-22", label: "Jamalpur" },
      { value: "BD-23", label: "Kishoreganj" },
      { value: "BD-24", label: "Madaripur" },
      { value: "BD-25", label: "Manikganj" },
      { value: "BD-26", label: "Munshiganj" },
      { value: "BD-27", label: "Mymensingh" },
      { value: "BD-28", label: "Narayanganj" },
      { value: "BD-29", label: "Narsingdi" },
      { value: "BD-30", label: "Netrakona" },
      { value: "BD-31", label: "Rajbari" },
      { value: "BD-32", label: "Shariatpur" },
      { value: "BD-33", label: "Sherpur" },
      { value: "BD-34", label: "Tangail" },
      { value: "BD-35", label: "Bagerhat" },
      { value: "BD-36", label: "Chuadanga" },
      { value: "BD-37", label: "Jessore" },
      { value: "BD-38", label: "Jhenaidah" },
      { value: "BD-39", label: "Khulna" },
      { value: "BD-40", label: "Kushtia" },
      { value: "BD-41", label: "Magura" },
      { value: "BD-42", label: "Meherpur" },
      { value: "BD-43", label: "Narail" },
      { value: "BD-44", label: "Satkhira" },
      { value: "BD-45", label: "Bogra" },
      { value: "BD-46", label: "Joypurhat" },
      { value: "BD-47", label: "Naogaon" },
      { value: "BD-48", label: "Natore" },
      { value: "BD-49", label: "Chapainawabganj" },
      { value: "BD-50", label: "Pabna" },
      { value: "BD-51", label: "Rajshahi" },
      { value: "BD-52", label: "Sirajganj" },
      { value: "BD-53", label: "Dinajpur" },
      { value: "BD-54", label: "Gaibandha" },
      { value: "BD-55", label: "Kurigram" },
      { value: "BD-56", label: "Lalmonirhat" },
      { value: "BD-57", label: "Nilphamari" },
      { value: "BD-58", label: "Panchagarh" },
      { value: "BD-59", label: "Rangpur" },
      { value: "BD-60", label: "Thakurgaon" },
      { value: "BD-61", label: "Habiganj" },
      { value: "BD-62", label: "Moulvibazar" },
      { value: "BD-63", label: "Sunamganj" },
      { value: "BD-64", label: "Sylhet" }
    ]
  ],
  [
    "BE",
    [
      { value: "ANT", label: "Antwerpen" },
      { value: "HAI", label: "Henegouwen" },
      { value: "LIE", label: "Luik" },
      { value: "LIM", label: "Limburg" },
      { value: "LUX", label: "Luxemburg" },
      { value: "NAM", label: "Namen" },
      { value: "OVL", label: "Oost-Vlaanderen" },
      { value: "VBR", label: "Vlaams-Brabant" },
      { value: "WBR", label: "Waals-Brabant" },
      { value: "WVL", label: "West-Vlaanderen" }
    ]
  ],
  [
    "BO",
    [
      { value: "BO-1", label: "Cercado" },
      { value: "BO-2", label: "Iténez" },
      { value: "BO-3", label: "José Ballivián" },
      { value: "BO-4", label: "Mamoré" },
      { value: "BO-5", label: "Marbán" },
      { value: "BO-6", label: "Moxos" },
      { value: "BO-7", label: "Vaca Díez" },
      { value: "BO-8", label: "Yacuma" },
      { value: "BO-9", label: "Azurduy" },
      { value: "BO-10", label: "Belisario Boeto" },
      { value: "BO-11", label: "Hernando Siles" },
      { value: "BO-12", label: "Jaime Zudáñez" },
      { value: "BO-13", label: "Luis Calvo" },
      { value: "BO-14", label: "Nor Cinti" },
      { value: "BO-15", label: "Oropeza" },
      { value: "BO-16", label: "Sud Cinti" },
      { value: "BO-17", label: "Tomina" },
      { value: "BO-18", label: "Yamparáez" },
      { value: "BO-19", label: "Arani" },
      { value: "BO-20", label: "Arque" },
      { value: "BO-21", label: "Ayopaya" },
      { value: "BO-22", label: "Capinota" },
      { value: "BO-23", label: "Carrasco" },
      { value: "BO-24", label: "Cercado" },
      { value: "BO-25", label: "Chapare" },
      { value: "BO-26", label: "Esteban Arce" },
      { value: "BO-27", label: "Germán Jordán" },
      { value: "BO-28", label: "Mizque" },
      { value: "BO-29", label: "Campero" },
      { value: "BO-30", label: "Punata" },
      { value: "BO-31", label: "Quillacollo" },
      { value: "BO-32", label: "Bolívar" },
      { value: "BO-33", label: "Tapacarí" },
      { value: "BO-34", label: "Tiraque" },
      { value: "BO-35", label: "Abel Iturralde" },
      { value: "BO-36", label: "Aroma" },
      { value: "BO-37", label: "Bautista Saavedra" },
      { value: "BO-38", label: "Caranavi" },
      { value: "BO-39", label: "Eliodoro Camacho" },
      { value: "BO-40", label: "Franz Tamayo" },
      { value: "BO-41", label: "Gualberto Villarroel" },
      { value: "BO-42", label: "Ingavi" },
      { value: "BO-43", label: "Inquisivi" },
      { value: "BO-44", label: "José Manuel Pando" },
      { value: "BO-45", label: "Larecaja" },
      { value: "BO-46", label: "Loayza" },
      { value: "BO-47", label: "Los Andes" },
      { value: "BO-48", label: "Manco Kapac" },
      { value: "BO-49", label: "Muñecas" },
      { value: "BO-50", label: "Nor Yungas" },
      { value: "BO-51", label: "Omasuyos" },
      { value: "BO-52", label: "Pacajes" },
      { value: "BO-53", label: "Murillo" },
      { value: "BO-54", label: "Sud Yungas" },
      { value: "BO-55", label: "Atahuallpa" },
      { value: "BO-56", label: "Carangas" },
      { value: "BO-57", label: "Cercado" },
      { value: "BO-58", label: "Eduardo Avaroa" },
      { value: "BO-59", label: "Ladislao Cabrera" },
      { value: "BO-60", label: "Litoral" },
      { value: "BO-61", label: "Nor Carangas" },
      { value: "BO-62", label: "Pantaléon Dalence" },
      { value: "BO-63", label: "Poopó" },
      { value: "BO-64", label: "Puerto de Mejillones" },
      { value: "BO-65", label: "Sajama" },
      { value: "BO-66", label: "San Pedro de Totora" },
      { value: "BO-67", label: "Saucarí" },
      { value: "BO-68", label: "Sebastián Pagador" },
      { value: "BO-69", label: "Sud Carangas" },
      { value: "BO-70", label: "Tomas Barrón" },
      { value: "BO-71", label: "Abuná" },
      { value: "BO-72", label: "Federico Román" },
      { value: "BO-73", label: "Madre de Dios" },
      { value: "BO-74", label: "Manuripi" },
      { value: "BO-75", label: "Nicolás Suárez" },
      { value: "BO-76", label: "Alonso de Ibáñez" },
      { value: "BO-77", label: "Antonio Quijarro" },
      { value: "BO-78", label: "Bernardino Bilbao" },
      { value: "BO-79", label: "Charcas" },
      { value: "BO-80", label: "Chayanta" },
      { value: "BO-81", label: "Cornelio Saavedra" },
      { value: "BO-82", label: "Daniel Campos" },
      { value: "BO-83", label: "Enrique Baldivieso" },
      { value: "BO-84", label: "José María Linares" },
      { value: "BO-85", label: "Modesto Omiste" },
      { value: "BO-86", label: "Nor Chichas" },
      { value: "BO-87", label: "Nor Lípez" },
      { value: "BO-88", label: "Rafael Bustillo" },
      { value: "BO-89", label: "Sur Chichas" },
      { value: "BO-90", label: "Sur Lípez" },
      { value: "BO-91", label: "Tomás Frías" },
      { value: "BO-92", label: "Andrés Ibáñez" },
      { value: "BO-93", label: "Ángel Sandoval" },
      { value: "BO-94", label: "Chiquitos" },
      { value: "BO-95", label: "Cordillera" },
      { value: "BO-96", label: "Florida" },
      { value: "BO-97", label: "Germán Busch" },
      { value: "BO-98", label: "Guarayos" },
      { value: "BO-99", label: "Ichilo" },
      { value: "BO-100", label: "Ignacio Warnes" },
      { value: "BO-101", label: "José Miguel de Velasco" },
      { value: "BO-102", label: "Manuel María Caballero" },
      { value: "BO-103", label: "Ñuflo de Chávez" },
      { value: "BO-104", label: "Obispo Santistevan" },
      { value: "BO-105", label: "Sara" },
      { value: "BO-106", label: "Vallegrande" },
      { value: "BO-107", label: "Aniceto Arce" },
      { value: "BO-108", label: "Burnet O'Connor" },
      { value: "BO-109", label: "Cercado" },
      { value: "BO-110", label: "Eustaquio Méndez" },
      { value: "BO-111", label: "Gran Chaco" },
      { value: "BO-112", label: "José María Avilés" }
    ]
  ],
  [
    "BR",
    [
      { value: "AC", label: "Acre" },
      { value: "AL", label: "Alagoas" },
      { value: "AP", label: "Amapá" },
      { value: "AM", label: "Amazonas" },
      { value: "BA", label: "Bahia" },
      { value: "CE", label: "Ceará" },
      { value: "DF", label: "Distrito Federal" },
      { value: "ES", label: "Espírito Santo" },
      { value: "GO", label: "Goiás" },
      { value: "MA", label: "Maranhão" },
      { value: "MT", label: "Mato Grosso" },
      { value: "MS", label: "Mato Grosso do Sul" },
      { value: "MG", label: "Minas Gerais" },
      { value: "PA", label: "Pará" },
      { value: "PB", label: "Paraíba" },
      { value: "PR", label: "Paraná" },
      { value: "PE", label: "Pernambuco" },
      { value: "PI", label: "Piauí" },
      { value: "RJ", label: "Rio de Janeiro" },
      { value: "RN", label: "Rio Grande do Norte" },
      { value: "RS", label: "Rio Grande do Sul" },
      { value: "RO", label: "Rondônia" },
      { value: "RR", label: "Roraima" },
      { value: "SC", label: "Santa Catarina" },
      { value: "SP", label: "São Paulo" },
      { value: "SE", label: "Sergipe" },
      { value: "TO", label: "Tocantins" }
    ]
  ],
  [
    "CA",
    [
      { value: "AB", label: "Alberta" },
      { value: "BC", label: "British Columbia" },
      { value: "MB", label: "Manitoba" },
      { value: "NB", label: "New Brunswick" },
      { value: "NL", label: "Newfoundland and Labrador" },
      { value: "NS", label: "Nova Scotia" },
      { value: "NU", label: "Nunavut" },
      { value: "NT", label: "Northwest Territories" },
      { value: "ON", label: "Ontario" },
      { value: "PE", label: "Prince Edward Island" },
      { value: "QC", label: "Quebec" },
      { value: "SK", label: "Saskatchewan" },
      { value: "YT", label: "Yukon" }
    ]
  ],
  [
    "CL",
    [
      { value: "CL-1", label: "Arica" },
      { value: "CL-2", label: "Parinacota" },
      { value: "CL-3", label: "Iquique" },
      { value: "CL-4", label: "Tamarugal" },
      { value: "CL-5", label: "Antofagasta" },
      { value: "CL-6", label: "El Loa" },
      { value: "CL-7", label: "Tocopilla" },
      { value: "CL-8", label: "Copiapó" },
      { value: "CL-9", label: "Huasco" },
      { value: "CL-10", label: "Chañaral" },
      { value: "CL-11", label: "Elqui" },
      { value: "CL-12", label: "Limarí" },
      { value: "CL-13", label: "Choapa" },
      { value: "CL-14", label: "Isla de Pascua" },
      { value: "CL-15", label: "Los Andes" },
      { value: "CL-16", label: "Marga Marga" },
      { value: "CL-17", label: "Petorca" },
      { value: "CL-18", label: "Quillota" },
      { value: "CL-19", label: "San Antonio" },
      { value: "CL-20", label: "San Felipe de Aconcagua" },
      { value: "CL-21", label: "Valparaíso" },
      { value: "CL-22", label: "Cachapoal" },
      { value: "CL-23", label: "Colchagua" },
      { value: "CL-24", label: "Cardenal Caro" },
      { value: "CL-25", label: "Talca" },
      { value: "CL-26", label: "Linares" },
      { value: "CL-27", label: "Curicó" },
      { value: "CL-28", label: "Cauquenes" },
      { value: "CL-29", label: "Concepción" },
      { value: "CL-30", label: "Ñuble" },
      { value: "CL-31", label: "Biobío" },
      { value: "CL-32", label: "Arauco" },
      { value: "CL-33", label: "Cautin" },
      { value: "CL-34", label: "Malleco" },
      { value: "CL-35", label: "Valdivia" },
      { value: "CL-36", label: "Ranco" },
      { value: "CL-37", label: "Llanquihue" },
      { value: "CL-38", label: "Osorno" },
      { value: "CL-39", label: "Chiloe" },
      { value: "CL-40", label: "Palena" },
      { value: "CL-41", label: "Coihaique" },
      { value: "CL-42", label: "Aisén" },
      { value: "CL-43", label: "General Carrera" },
      { value: "CL-44", label: "Capitan Prat" },
      { value: "CL-45", label: "Magallanes" },
      { value: "CL-46", label: "Ultima Esperanza" },
      { value: "CL-47", label: "Tierra del Fuego" },
      { value: "CL-48", label: "Antártica Chilena" },
      { value: "CL-49", label: "Santiago" },
      { value: "CL-50", label: "Cordillera" },
      { value: "CL-51", label: "Maipo" },
      { value: "CL-52", label: "Talagante" },
      { value: "CL-53", label: "Melipilla" },
      { value: "CL-54", label: "Chacabuco" }
    ]
  ],
  [
    "CN",
    [
      { value: "CQ", label: "Chongqing" },
      { value: "HL", label: "Heilongjiang" },
      { value: "JL", label: "Jilin" },
      { value: "HI", label: "Hainan" },
      { value: "BJ", label: "Beijing" },
      { value: "LN", label: "Liaoning" },
      { value: "NM", label: "Inner Mongolia" },
      { value: "XZ", label: "Tibet" },
      { value: "QH", label: "Qinghai" },
      { value: "NX", label: "Ningxia" },
      { value: "XJ", label: "Xinjiang" },
      { value: "GS", label: "Gansu" },
      { value: "HE", label: "Hebei" },
      { value: "HA", label: "Henan" },
      { value: "HB", label: "Hubei" },
      { value: "HN", label: "Hunan" },
      { value: "SD", label: "Shandong" },
      { value: "JS", label: "Jiangsu" },
      { value: "AH", label: "Anhui" },
      { value: "SX", label: "Shanxi" },
      { value: "SN", label: "Shaanxi" },
      { value: "SC", label: "Sichuan" },
      { value: "YN", label: "Yunnan" },
      { value: "GZ", label: "Guizhou" },
      { value: "ZJ", label: "Zhejiang" },
      { value: "FJ", label: "Fujian" },
      { value: "GX", label: "Guangxi" },
      { value: "SH", label: "Shanghai" },
      { value: "TJ", label: "Tianjin" },
      { value: "HK", label: "Hong Kong" },
      { value: "MC", label: "Macau" },
      { value: "TW", label: "Taiwan" },
      { value: "JX ", label: "Jiangxi" },
      { value: "GD", label: "Guangdong" }
    ]
  ],
  [
    "CU",
    [
      { value: "CU-1", label: "Camagüey" },
      { value: "CU-2", label: "Ciego de Ávila" },
      { value: "CU-3", label: "Cienfuegos" },
      { value: "CU-4", label: "Havana" },
      { value: "CU-5", label: "Bayamo" },
      { value: "CU-6", label: "Guantánamo" },
      { value: "CU-7", label: "Holguín" },
      { value: "CU-8", label: "Nueva Gerona" },
      { value: "CU-9", label: "Artemisa" },
      { value: "CU-10", label: "Las Tunas" },
      { value: "CU-11", label: "Matanzas" },
      { value: "CU-12", label: "San José de las Lajas" },
      { value: "CU-13", label: "Pinar del Río" },
      { value: "CU-14", label: "Sancti Spíritus" },
      { value: "CU-15", label: "Santiago de Cuba" },
      { value: "CU-16", label: "Santa Clara" }
    ]
  ],
  [
    "DE",
    [
      { value: "BW", label: "Baden-Württemberg" },
      { value: "BY", label: "Bayern" },
      { value: "BE", label: "Berlin" },
      { value: "BB", label: "Brandenburg" },
      { value: "HB", label: "Bremen" },
      { value: "HH", label: "Hamburg" },
      { value: "HE", label: "Hessen" },
      { value: "MV", label: "Mecklenburg-Vorpommern" },
      { value: "NI", label: "Niedersachsen" },
      { value: "NW", label: "Nordrhein-Westfalen" },
      { value: "RP", label: "Rheinland-Pfalz" },
      { value: "SL", label: "Saarland" },
      { value: "SN", label: "Sachsen" },
      { value: "ST", label: "Sachsen-Anhalt" },
      { value: "SH", label: "Schleswig-Holstein" },
      { value: "TH", label: "Thüringen" }
    ]
  ],
  [
    "DK",
    [
      { value: "DK-1", label: "Hovedstaden" },
      { value: "DK-2", label: "Midtjylland" },
      { value: "DK-3", label: "Nordjylland" },
      { value: "DK-4", label: "Sjælland" },
      { value: "DK-5", label: "Syddanmark" }
    ]
  ],
  [
    "ES",
    [
      { value: "C", label: "La Coruña" },
      { value: "LU", label: "Lugo" },
      { value: "BI", label: "Vizcaya" },
      { value: "SS", label: "Guipúzcoa" },
      { value: "HU", label: "Huesca" },
      { value: "L", label: "Lérida" },
      { value: "GI", label: "Gerona" },
      { value: "B", label: "Barcelona" },
      { value: "T", label: "Tarragona" },
      { value: "CS", label: "Castellón" },
      { value: "V", label: "Valencia" },
      { value: "A", label: "Alicante" },
      { value: "MU", label: "Murcia" },
      { value: "Z", label: "Zaragoza" },
      { value: "TE", label: "Teruel" },
      { value: "CU", label: "Cuenca" },
      { value: "AB", label: "Albacete" },
      { value: "AL", label: "Almeria" },
      { value: "GR", label: "Granada" },
      { value: "MA", label: "Málaga" },
      { value: "TF", label: "Tenerife" },
      { value: "CA", label: "Cádiz" },
      { value: "SE", label: "Sevilla" },
      { value: "H", label: "Huelva" },
      { value: "GC", label: "Las Palmas" },
      { value: "M", label: "Madrid" },
      { value: "BA", label: "Badajoz" },
      { value: "CC", label: "Cáceres" },
      { value: "TO", label: "Toledo" },
      { value: "CR", label: "Ciudad Real" },
      { value: "SA", label: "Salamanca" },
      { value: "CO", label: "Córdoba" },
      { value: "J", label: "Jaén" },
      { value: "AV", label: "Ávila" },
      { value: "VA", label: "Valladolid" },
      { value: "ZA", label: "Zamora" },
      { value: "VI", label: "Álava" },
      { value: "SG", label: "Segovia" },
      { value: "BU", label: "Burgos" },
      { value: "PO", label: "Pontevedra" },
      { value: "LE", label: "León" },
      { value: "OU", label: "Orense" },
      { value: "P", label: "Palencia" },
      { value: "LO", label: "La Rioja" },
      { value: "SO", label: "Soria" },
      { value: "GU", label: "Guadalajara" }
    ]
  ],
  [
    "ET",
    [
      { value: "ET-1", label: "Addis Ababa" },
      { value: "ET-2", label: "Afar Region" },
      { value: "ET-3", label: "Amhara Region" },
      { value: "ET-4", label: "Benishangul-Gumuz" },
      { value: "ET-5", label: "Dire Dawa" },
      { value: "ET-6", label: "Gambela" },
      { value: "ET-7", label: "Harari" },
      { value: "ET-8", label: "Oromia" },
      { value: "ET-9", label: "Somali" },
      {
        value: "ET-10",
        label: "Southern Nations, Nationalities, and Peoples' Region"
      },
      { value: "ET-11", label: "Tigray Region" }
    ]
  ],
  [
    "ID",
    [
      { value: "ID-AC", label: "Special Region of Aceh" },
      { value: "ID-BA", label: "Bali" },
      {
        value: "ID-BB",
        label: "Bangka–Belitung Islands"
      },
      { value: "ID-BT", label: "Banten" },
      { value: "ID-BE", label: "Bengkulu" },
      { value: "ID-JT", label: "Central Java" },
      { value: "ID-KT", label: "Central Kalimantan" },
      { value: "ID-ST", label: "Central Sulawesi" },
      { value: "ID-JI", label: "East Java" },
      { value: "ID-KI", label: "East Kalimantan" },
      { value: "ID-NT", label: "East Nusa Tenggara" },
      { value: "ID-GO", label: "Gorontalo" },
      {
        value: "ID-JK",
        label: "Jakarta Special Capital Region"
      },
      { value: "ID-JA", label: "Jambi" },
      { value: "ID-LA", label: "Lampung" },
      { value: "ID-MA", label: "Maluku" },
      { value: "ID-KU", label: "North Kalimantan" },
      { value: "ID-MU", label: "North Maluku" },
      { value: "ID-SA", label: "North Sulawesi" },
      { value: "ID-SU", label: "North Sumatra" },
      { value: "ID-PA", label: "Special Region of Papua" },
      { value: "ID-RI", label: "Riau" },
      { value: "ID-KR", label: "Riau Islands" },
      { value: "ID-SG", label: "Southeast Sulawesi" },
      { value: "ID-KS", label: "South Kalimantan" },
      { value: "ID-SN", label: "South Sulawesi" },
      { value: "ID-SS", label: "South Sumatra" },
      { value: "ID-JB", label: "West Java" },
      { value: "ID-KB", label: "West Kalimantan" },
      { value: "ID-NB", label: "West Nusa Tenggara" },
      { value: "ID-PB", label: "Special Region of West Papua" },
      { value: "ID-SR", label: "West Sulawesi" },
      { value: "ID-SB", label: "West Sumatra" },
      { value: "ID-YO", label: "Special Region of Yogyakarta" }
    ]
  ],
  [
    "IN",
    [
      { value: "AP", label: "Andhra Pradesh" },
      { value: "AR", label: "Arunachal Pradesh" },
      { value: "AS", label: "Assam" },
      { value: "BR", label: "Bihar" },
      { value: "CT", label: "Chhattisgarh" },
      { value: "GA", label: "Goa" },
      { value: "GJ", label: "Gujarat" },
      { value: "HR", label: "Haryana" },
      { value: "HP", label: "Himachal Pradesh" },
      { value: "JK", label: "Jammu and Kashmir" },
      { value: "JH", label: "Jharkhand" },
      { value: "KA", label: "Karnataka" },
      { value: "KL", label: "Kerala" },
      { value: "MP", label: "Madhya Pradesh" },
      { value: "MH", label: "Maharashtra" },
      { value: "MN", label: "Manipur" },
      { value: "ML", label: "Meghalaya" },
      { value: "MZ", label: "Mizoram" },
      { value: "NL", label: "Nagaland" },
      { value: "OR", label: "Odisha" },
      { value: "PB", label: "Punjab" },
      { value: "RJ", label: "Rajasthan" },
      { value: "SK", label: "Sikkim" },
      { value: "TN", label: "Tamil Nadu" },
      { value: "TG", label: "Telangana" },
      { value: "TR", label: "Tripura" },
      { value: "UP", label: "Uttar Pradesh" },
      { value: "UT", label: "Uttarakhand" },
      { value: "WB", label: "West Bengal" },
      { value: "AN", label: "Andaman and Nicobar Islands" },
      { value: "CH", label: "Chandigarh" },
      { value: "DN", label: "Dadra and Nagar Haveli" },
      { value: "DD", label: "Daman and Diu" },
      { value: "LD", label: "Lakshadweep" },
      {
        value: "DL",
        label: "National Capital Territory of Delhi"
      },
      { value: "PY", label: "Puducherry" }
    ]
  ],
  [
    "JO",
    [
      { value: "JO-1", label: "Irbid" },
      { value: "JO-2", label: "Ajloun" },
      { value: "JO-3", label: "Jerash" },
      { value: "JO-4", label: "Mafraq" },
      { value: "JO-5", label: "Balqa" },
      { value: "JO-6", label: "Amman" },
      { value: "JO-7", label: "Zarqa" },
      { value: "JO-8", label: "Madaba" },
      { value: "JO-9", label: "Karak" },
      { value: "JO-10", label: "Tafilah" },
      { value: "JO-11", label: "Ma'an" },
      { value: "JO-12", label: "Aqaba" }
    ]
  ],
  [
    "JP",
    [
      { value: "JP-1", label: "Aichi" },
      { value: "JP-2", label: "Akita" },
      { value: "JP-3", label: "Aomori" },
      { value: "JP-4", label: "Chiba" },
      { value: "JP-5", label: "Ehime" },
      { value: "JP-6", label: "Fukui" },
      { value: "JP-7", label: "Fukuoka" },
      { value: "JP-8", label: "Fukushima" },
      { value: "JP-9", label: "Gifu" },
      { value: "JP-10", label: "Gunma" },
      { value: "JP-11", label: "Hiroshima" },
      { value: "JP-12", label: "Hokkaidō" },
      { value: "JP-13", label: "Hyogo" },
      { value: "JP-14", label: "Ibaraki" },
      { value: "JP-15", label: "Ishikawa" },
      { value: "JP-16", label: "Iwate" },
      { value: "JP-17", label: "Kagawa" },
      { value: "JP-18", label: "Kagoshima" },
      { value: "JP-19", label: "Kanagawa" },
      { value: "JP-20", label: "Kochi" },
      { value: "JP-21", label: "Kumamoto" },
      { value: "JP-22", label: "Kyoto" },
      { value: "JP-23", label: "Mie" },
      { value: "JP-24", label: "Miyagi" },
      { value: "JP-25", label: "Miyazaki" },
      { value: "JP-26", label: "Nagano" },
      { value: "JP-27", label: "Nagasaki" },
      { value: "JP-28", label: "Nara" },
      { value: "JP-29", label: "Niigata" },
      { value: "JP-30", label: "Oita" },
      { value: "JP-31", label: "Okayama" },
      { value: "JP-32", label: "Okinawa" },
      { value: "JP-33", label: "Ōsaka" },
      { value: "JP-34", label: "Saga" },
      { value: "JP-35", label: "Saitama" },
      { value: "JP-36", label: "Shiga" },
      { value: "JP-37", label: "Shimane" },
      { value: "JP-38", label: "Shizuoka" },
      { value: "JP-39", label: "Tochigi" },
      { value: "JP-40", label: "Tokushima" },
      { value: "JP-41", label: "Tokyo" },
      { value: "JP-42", label: "Tottori" },
      { value: "JP-43", label: "Toyama" },
      { value: "JP-44", label: "Wakayama" },
      { value: "JP-45", label: "Yamagata" },
      { value: "JP-46", label: "Yamaguchi" },
      { value: "JP-47", label: "Yamanashi" }
    ]
  ],
  [
    "KH",
    [
      { value: "KH-1", label: "Phnom Penh Municipality" },
      { value: "KH-2", label: "Banteay Meanchey" },
      { value: "KH-3", label: "Battambang" },
      { value: "KH-4", label: "Kampong Cham" },
      { value: "KH-5", label: "Kampong Chhnang" },
      { value: "KH-6", label: "Kampong Speu" },
      { value: "KH-7", label: "Kampong Thom" },
      { value: "KH-8", label: "Kampot" },
      { value: "KH-9", label: "Kandal" },
      { value: "KH-10", label: "Koh Kong" },
      { value: "KH-11", label: "Kep" },
      { value: "KH-12", label: "Kratie" },
      { value: "KH-13", label: "Mondulkiri" },
      { value: "KH-14", label: "Oddar Meanchey" },
      { value: "KH-15", label: "Pailin" },
      { value: "KH-16", label: "Preah Sihanouk" },
      { value: "KH-17", label: "Preah Vihear" },
      { value: "KH-18", label: "Pursat" },
      { value: "KH-19", label: "Prey Veng" },
      { value: "KH-20", label: "Ratanakiri" },
      { value: "KH-21", label: "Siem Reap" },
      { value: "KH-22", label: "Stung Treng" },
      { value: "KH-23", label: "Svay Rieng" },
      { value: "KH-24", label: "Takeo" },
      { value: "KH-25", label: "Tbong Khmum" }
    ]
  ],
  [
    "MX",
    [
      { value: "AG", label: "Aguascalientes" },
      { value: "BC", label: "Baja California" },
      { value: "BS", label: "Baja California Sur" },
      { value: "CM", label: "Campeche" },
      { value: "CS", label: "Chiapas" },
      { value: "CH", label: "Chihuahua" },
      { value: "MX", label: "Coahuila" },
      { value: "CL", label: "Colima" },
      { value: "DF", label: "Federal District" },
      { value: "DG", label: "Durango" },
      { value: "GT", label: "Guanajuato" },
      { value: "GR", label: "Guerrero" },
      { value: "HG", label: "Hidalgo" },
      { value: "JA", label: "Jalisco" },
      { value: "ME", label: "Mexico" },
      { value: "MI", label: "Michoacán" },
      { value: "MO", label: "Morelos" },
      { value: "NA", label: "Nayarit" },
      { value: "NL", label: "Nuevo León" },
      { value: "OA", label: "Oaxaca" },
      { value: "PU", label: "Puebla" },
      { value: "QE", label: "Querétaro" },
      { value: "QR", label: "Quintana Roo" },
      { value: "SL", label: "San Luis Potosí" },
      { value: "SI", label: "Sinaloa" },
      { value: "SO", label: "Sonora" },
      { value: "TB", label: "Tabasco" },
      { value: "TM", label: "Tamaulipas" },
      { value: "TL", label: "Tlaxcala" },
      { value: "VE", label: "Veracruz" },
      { value: "YU", label: "Yucatán" },
      { value: "ZA", label: "Zacatecas" }
    ]
  ],
  [
    "NG",
    [
      { value: "NG-1", label: "Anambra" },
      { value: "NG-2", label: "Enugu" },
      { value: "NG-3", label: "Akwa Ibom" },
      { value: "NG-4", label: "Adamawa" },
      { value: "NG-5", label: "Abia" },
      { value: "NG-6", label: "Bauchi" },
      { value: "NG-7", label: "Bayelsa" },
      { value: "NG-8", label: "Benue" },
      { value: "NG-9", label: "Borno" },
      { value: "NG-10", label: "Cross River" },
      { value: "NG-11", label: "Delta" },
      { value: "NG-12", label: "Ebonyi" }
    ]
  ],
  [
    "NL",
    [
      { value: "DR", label: "Drenthe" },
      { value: "FL", label: "Flevoland" },
      { value: "FR", label: "Friesland" },
      { value: "GD", label: "Gelderland" },
      { value: "GR", label: "Groningen" },
      { value: "LB", label: "Limburg" },
      { value: "NB", label: "Noord-Brabant" },
      { value: "NH", label: "Noord-Holland" },
      { value: "OV", label: "Overijssel" },
      { value: "UT", label: "Utrecht" },
      { value: "ZH", label: "Zuid-Holland" },
      { value: "ZL", label: "Zeeland" },
      { value: "AW", label: "Aruba" },
      { value: "BQ", label: "Bonaire" },
      { value: "BQ2", label: "Saba" },
      { value: "BQ3", label: "Sint Eustatius" },
      { value: "CW", label: "Curaçao" },
      { value: "SX", label: "Sint Maarten" }
    ]
  ],
  [
    "PE",
    [
      { value: "PE-1", label: "Chachapoyas" },
      { value: "PE-2", label: "Bagua" },
      { value: "PE-3", label: "Bongará" },
      { value: "PE-4", label: "Condorcanqui" },
      { value: "PE-5", label: "Luya" },
      { value: "PE-6", label: "Rodríguez de Mendoza" },
      { value: "PE-7", label: "Utcubamba" },
      { value: "PE-8", label: "Huaraz" },
      { value: "PE-9", label: "Aija" },
      { value: "PE-10", label: "Antonio Raymondi" },
      { value: "PE-11", label: "Asunción" },
      { value: "PE-12", label: "Bolognesi" },
      { value: "PE-13", label: "Carhuaz" },
      { value: "PE-14", label: "Carlos Fermín Fitzcarrald" },
      { value: "PE-15", label: "Casma" },
      { value: "PE-16", label: "Corongo" },
      { value: "PE-17", label: "Huari" },
      { value: "PE-18", label: "Huarmey" },
      { value: "PE-19", label: "Huaylas" },
      { value: "PE-20", label: "Mariscal Luzuriaga" },
      { value: "PE-21", label: "Ocros" },
      { value: "PE-22", label: "Pallasca" },
      { value: "PE-23", label: "Pomabamba" },
      { value: "PE-24", label: "Recuay" },
      { value: "PE-25", label: "Santa" },
      { value: "PE-26", label: "Sihuas" },
      { value: "PE-27", label: "Yungay" },
      { value: "PE-28", label: "Abancay" },
      { value: "PE-29", label: "Andahuaylas" },
      { value: "PE-30", label: "Antabamba" },
      { value: "PE-31", label: "Aymaraes" },
      { value: "PE-32", label: "Cotabambas" },
      { value: "PE-33", label: "Chincheros" },
      { value: "PE-34", label: "Grau" },
      { value: "PE-35", label: "Arequipa" },
      { value: "PE-36", label: "Camaná" },
      { value: "PE-37", label: "Caravelí" },
      { value: "PE-38", label: "Castilla" },
      { value: "PE-39", label: "Caylloma" },
      { value: "PE-40", label: "Condesuyos" },
      { value: "PE-41", label: "Islay" },
      { value: "PE-42", label: "La Unión" },
      { value: "PE-43", label: "Huamanga" },
      { value: "PE-44", label: "Cangallo" },
      { value: "PE-45", label: "Huanca Sancos" },
      { value: "PE-46", label: "Huanta" },
      { value: "PE-47", label: "La Mar" },
      { value: "PE-48", label: "Lucanas" },
      { value: "PE-49", label: "Parinacochas" },
      { value: "PE-50", label: "Páucar del Sara Sara" },
      { value: "PE-51", label: "Sucre" },
      { value: "PE-52", label: "Víctor Fajardo" },
      { value: "PE-53", label: "Vilcas Huamán" },
      { value: "PE-54", label: "Cajamarca" },
      { value: "PE-55", label: "Cajabamba" },
      { value: "PE-56", label: "Celendín" },
      { value: "PE-57", label: "Chota" },
      { value: "PE-58", label: "Contumazá" },
      { value: "PE-59", label: "Cutervo" },
      { value: "PE-60", label: "Hualgayoc" },
      { value: "PE-61", label: "Jaén" },
      { value: "PE-62", label: "San Ignacio" },
      { value: "PE-63", label: "San Marcos" },
      { value: "PE-64", label: "San Miguel" },
      { value: "PE-65", label: "San Pablo" },
      { value: "PE-66", label: "Santa Cruz" },
      { value: "PE-67", label: "Callao" },
      { value: "PE-68", label: "Cusco" },
      { value: "PE-69", label: "Acomayo" },
      { value: "PE-70", label: "Anta" },
      { value: "PE-71", label: "Calca" },
      { value: "PE-72", label: "Canas" },
      { value: "PE-73", label: "Canchis" },
      { value: "PE-74", label: "Chumbivilcas" },
      { value: "PE-75", label: "Espinar" },
      { value: "PE-76", label: "La Convención" },
      { value: "PE-77", label: "Paruro" },
      { value: "PE-78", label: "Paucartambo" },
      { value: "PE-79", label: "Quispicanchi" },
      { value: "PE-80", label: "Urubamba" },
      { value: "PE-81", label: "Huancavelica" },
      { value: "PE-82", label: "Acobamba" },
      { value: "PE-83", label: "Angaraes" },
      { value: "PE-84", label: "Castrovirreyna" },
      { value: "PE-85", label: "Churcampa" },
      { value: "PE-86", label: "Huaytará" },
      { value: "PE-87", label: "Tayacaja" },
      { value: "PE-88", label: "Huánuco" },
      { value: "PE-89", label: "Ambo" },
      { value: "PE-90", label: "Dos de Mayo" },
      { value: "PE-91", label: "Huacaybamba" },
      { value: "PE-92", label: "Huamalíes" },
      { value: "PE-93", label: "Leoncio Prado" },
      { value: "PE-94", label: "Marañón" },
      { value: "PE-95", label: "Pachitea" },
      { value: "PE-96", label: "Puerto Inca" },
      { value: "PE-97", label: "Lauricocha" },
      { value: "PE-98", label: "Yarowilca" },
      { value: "PE-99", label: "Ica" },
      { value: "PE-100", label: "Chincha" },
      { value: "PE-101", label: "Nazca" },
      { value: "PE-102", label: "Palpa" },
      { value: "PE-103", label: "Pisco" },
      { value: "PE-104", label: "Huancayo" },
      { value: "PE-105", label: "Concepción" },
      { value: "PE-106", label: "Chanchamayo" },
      { value: "PE-107", label: "Jauja" },
      { value: "PE-108", label: "Junín" },
      { value: "PE-109", label: "Satipo" },
      { value: "PE-110", label: "Tarma" },
      { value: "PE-111", label: "Yauli" },
      { value: "PE-112", label: "Chupaca" },
      { value: "PE-113", label: "Trujillo" },
      { value: "PE-114", label: "Ascope" },
      { value: "PE-115", label: "Bolívar" },
      { value: "PE-116", label: "Chepén" },
      { value: "PE-117", label: "Julcán" },
      { value: "PE-118", label: "Otuzco" },
      { value: "PE-119", label: "Pacasmayo" },
      { value: "PE-120", label: "Pataz" },
      { value: "PE-121", label: "Sánchez Carrión" },
      { value: "PE-122", label: "Santiago de Chuco" },
      { value: "PE-123", label: "Gran Chimú" },
      { value: "PE-124", label: "Virú" },
      { value: "PE-125", label: "Chiclayo" },
      { value: "PE-126", label: "Ferreñafe" },
      { value: "PE-127", label: "Lambayeque" },
      { value: "PE-128", label: "Lima" },
      { value: "PE-129", label: "Huaura" },
      { value: "PE-130", label: "Barranca" },
      { value: "PE-131", label: "Cajatambo" },
      { value: "PE-132", label: "Canta" },
      { value: "PE-133", label: "Cañete" },
      { value: "PE-134", label: "Huaral" },
      { value: "PE-135", label: "Huarochirí" },
      { value: "PE-136", label: "Oyón" },
      { value: "PE-137", label: "Yauyos" },
      { value: "PE-138", label: "Maynas" },
      { value: "PE-139", label: "Aliaso Amazonas" },
      { value: "PE-140", label: "Loreto" },
      { value: "PE-141", label: "Mariscal Ramón Castilla" },
      { value: "PE-142", label: "Putumayo" },
      { value: "PE-143", label: "Requena" },
      { value: "PE-144", label: "Ucayali" },
      { value: "PE-145", label: "Datem del Marañón" },
      { value: "PE-146", label: "Tambopata" },
      { value: "PE-147", label: "Manú" },
      { value: "PE-148", label: "Tahuamanu" },
      { value: "PE-149", label: "Mariscal Nieto" },
      { value: "PE-150", label: "General Sánchez Cerro" },
      { value: "PE-151", label: "Ilo" },
      { value: "PE-152", label: "Pasco" },
      { value: "PE-153", label: "Daniel Alcídes Carrión" },
      { value: "PE-154", label: "Oxapampa" },
      { value: "PE-155", label: "Piura" },
      { value: "PE-156", label: "Ayabaca" },
      { value: "PE-157", label: "Huancabamba" },
      { value: "PE-158", label: "Morropón" },
      { value: "PE-159", label: "Paita" },
      { value: "PE-160", label: "Sullana" },
      { value: "PE-161", label: "Talara" },
      { value: "PE-162", label: "Sechura" },
      { value: "PE-163", label: "Puno" },
      { value: "PE-164", label: "Azángaro" },
      { value: "PE-165", label: "Carabaya" },
      { value: "PE-166", label: "Chucuito" },
      { value: "PE-167", label: "El Collao" },
      { value: "PE-168", label: "Huancané" },
      { value: "PE-169", label: "Lampa" },
      { value: "PE-170", label: "Melgar" },
      { value: "PE-171", label: "Moho" },
      { value: "PE-172", label: "San Antonio de Putina" },
      { value: "PE-173", label: "San Román" },
      { value: "PE-174", label: "Sandia" },
      { value: "PE-175", label: "Yunguyo" },
      { value: "PE-176", label: "Moyobamba" },
      { value: "PE-177", label: "Bellavista" },
      { value: "PE-178", label: "El Dorado" },
      { value: "PE-179", label: "Huallaga" },
      { value: "PE-180", label: "Lamas" },
      { value: "PE-181", label: "Mariscal Cáceres" },
      { value: "PE-182", label: "Picota" },
      { value: "PE-183", label: "Rioja" },
      { value: "PE-184", label: "San Martín" },
      { value: "PE-185", label: "Tocache" },
      { value: "PE-186", label: "Tacna" },
      { value: "PE-187", label: "Candarave" },
      { value: "PE-188", label: "Jorge Basadre" },
      { value: "PE-189", label: "Tarata" },
      { value: "PE-190", label: "Tumbes" },
      { value: "PE-191", label: "Contralmirante Villar" },
      { value: "PE-192", label: "Zarumilla" },
      { value: "PE-193", label: "Coronel Portillo" },
      { value: "PE-194", label: "Atalaya" },
      { value: "PE-195", label: "Padre Abad" },
      { value: "PE-196", label: "Purús" }
    ]
  ],
  [
    "PH",
    [
      { value: "PH-1", label: "Abra" },
      { value: "PH-2", label: "Agusan del Norte" },
      { value: "PH-3", label: "Agusan del Sur" },
      { value: "PH-4", label: "Aklan" },
      { value: "PH-5", label: "Albay" },
      { value: "PH-6", label: "Antique" },
      { value: "PH-7", label: "Apayao" },
      { value: "PH-8", label: "Aurora" },
      { value: "PH-9", label: "Basilan" },
      { value: "PH-10", label: "Bataan" },
      { value: "PH-11", label: "Batanes" },
      { value: "PH-12", label: "Batangas" },
      { value: "PH-13", label: "Benguet" },
      { value: "PH-14", label: "Biliran" },
      { value: "PH-15", label: "Bohol" },
      { value: "PH-16", label: "Bukidnon" },
      { value: "PH-17", label: "Bulacan" },
      { value: "PH-18", label: "Cagayan" },
      { value: "PH-19", label: "Camarines Norte" },
      { value: "PH-20", label: "Camarines Sur" },
      { value: "PH-21", label: "Camiguin" },
      { value: "PH-22", label: "Capiz" },
      { value: "PH-23", label: "Catanduanes" },
      { value: "PH-24", label: "Cavite" },
      { value: "PH-25", label: "Cebu" },
      { value: "PH-26", label: "Compostela Valley" },
      { value: "PH-27", label: "Cotabato" },
      { value: "PH-28", label: "Davao del Norte" },
      { value: "PH-29", label: "Davao del Sur" },
      { value: "PH-30", label: "Davao Occidental" },
      { value: "PH-31", label: "Davao Oriental" },
      { value: "PH-32", label: "Dinagat Islands" },
      { value: "PH-33", label: "Eastern Samar" },
      { value: "PH-34", label: "Guimaras" },
      { value: "PH-35", label: "Ifugao" },
      { value: "PH-36", label: "Ilocos Norte" },
      { value: "PH-37", label: "Ilocos Sur" },
      { value: "PH-38", label: "Iloilo" },
      { value: "PH-39", label: "Isabela" },
      { value: "PH-40", label: "Kalinga" },
      { value: "PH-41", label: "La Union" },
      { value: "PH-42", label: "Laguna" },
      { value: "PH-43", label: "Lanao del Norte" },
      { value: "PH-44", label: "Lanao del Sur" },
      { value: "PH-45", label: "Leyte" },
      { value: "PH-46", label: "Maguindanao" },
      { value: "PH-47", label: "Marinduque" },
      { value: "PH-48", label: "Masbate" },
      { value: "PH-49", label: "Misamis Occidental" },
      { value: "PH-50", label: "Misamis Oriental" },
      { value: "PH-51", label: "Mountain Province" },
      { value: "PH-52", label: "Negros Occidental" },
      { value: "PH-53", label: "Negros Oriental" },
      { value: "PH-54", label: "Northern Samar" },
      { value: "PH-55", label: "Nueva Ecija" },
      { value: "PH-56", label: "Nueva Vizcaya" },
      { value: "PH-57", label: "Occidental Mindoro" },
      { value: "PH-58", label: "Oriental Mindoro" },
      { value: "PH-59", label: "Palawan" },
      { value: "PH-60", label: "Pampanga" },
      { value: "PH-61", label: "Pangasinan" },
      { value: "PH-62", label: "Quezon" },
      { value: "PH-63", label: "Quirino" },
      { value: "PH-64", label: "Rizal" },
      { value: "PH-65", label: "Romblon" },
      { value: "PH-66", label: "Samar" },
      { value: "PH-67", label: "Sarangani" },
      { value: "PH-68", label: "Siquijor" },
      { value: "PH-69", label: "Sorsogon" },
      { value: "PH-70", label: "South Cotabato" },
      { value: "PH-71", label: "Southern Leyte" },
      { value: "PH-72", label: "Sultan Kudarat" },
      { value: "PH-73", label: "Sulu" },
      { value: "PH-74", label: "Surigao del Norte" },
      { value: "PH-75", label: "Surigao del Sur" },
      { value: "PH-76", label: "Tarlac" },
      { value: "PH-77", label: "Tawi-Tawi" },
      { value: "PH-78", label: "Zambales" },
      { value: "PH-79", label: "Zamboanga del Norte" },
      { value: "PH-80", label: "Zamboanga del Sur" },
      { value: "PH-81", label: "Zamboanga Sibugay" },
      { value: "PH-82", label: "Metro Manila" }
    ]
  ],
  [
    "PK",
    [
      { value: "PK-1", label: "Azad Kashmir" },
      { value: "PK-2", label: "Bahawalpur" },
      { value: "PK-3", label: "Bannu" },
      { value: "PK-4", label: "Dera Ghazi Khan" },
      { value: "PK-5", label: "Dera Ismail Khan" },
      { value: "PK-6", label: "Faisalabad" },
      { value: "PK-7", label: "Federally Administered Tribal Areas" },
      { value: "PK-8", label: "Gujranwala" },
      { value: "PK-9", label: "Hazara" },
      { value: "PK-10", label: "Hyderabad" },
      { value: "PK-11", label: "Islamabad" },
      { value: "PK-12", label: "Kalat" },
      { value: "PK-13", label: "Karachi" },
      { value: "PK-14", label: "Kohat" },
      { value: "PK-15", label: "Lahore" },
      { value: "PK-16", label: "Larkana" },
      { value: "PK-17", label: "Makran" },
      { value: "PK-18", label: "Malakand" },
      { value: "PK-19", label: "Mardan" },
      { value: "PK-20", label: "Mirpur Khas" },
      { value: "PK-21", label: "Multan" },
      { value: "PK-22", label: "Nasirabad" },
      { value: "PK-23", label: "Northern Areas" },
      { value: "PK-24", label: "Peshawar" },
      { value: "PK-25", label: "Quetta" },
      { value: "PK-26", label: "Rawalpindi" },
      { value: "PK-27", label: "Sargodha" },
      { value: "PK-28", label: "Sahiwal" },
      { value: "PK-29", label: "Sibi" },
      { value: "PK-30", label: "Sukkur" },
      { value: "PK-31", label: "Zhob" }
    ]
  ],
  [
    "TR",
    [
      { value: "TR-1", label: "Adana" },
      { value: "TR-2", label: "Adıyaman" },
      { value: "TR-3", label: "Afyonkarahisar" },
      { value: "TR-4", label: "Ağrı" },
      { value: "TR-5", label: "Amasya" },
      { value: "TR-6", label: "Ankara" },
      { value: "TR-7", label: "Antalya" },
      { value: "TR-8", label: "Artvin" },
      { value: "TR-9", label: "Aydın" },
      { value: "TR-10", label: "Balıkesir" },
      { value: "TR-11", label: "Bilecik" },
      { value: "TR-12", label: "Bingöl" },
      { value: "TR-13", label: "Bitlis" },
      { value: "TR-14", label: "Bolu" },
      { value: "TR-15", label: "Burdur" },
      { value: "TR-16", label: "Bursa" },
      { value: "TR-17", label: "Çanakkale" },
      { value: "TR-18", label: "Çankırı" },
      { value: "TR-19", label: "Çorum" },
      { value: "TR-20", label: "Denizli" },
      { value: "TR-21", label: "Diyarbakır" },
      { value: "TR-22", label: "Edirne" },
      { value: "TR-23", label: "Elazığ" },
      { value: "TR-24", label: "Erzincan" },
      { value: "TR-25", label: "Erzurum" },
      { value: "TR-26", label: "Eskişehir" },
      { value: "TR-27", label: "Gaziantep" },
      { value: "TR-28", label: "Giresun" },
      { value: "TR-29", label: "Gümüşhane" },
      { value: "TR-30", label: "Hakkâri" },
      { value: "TR-31", label: "Hatay" },
      { value: "TR-32", label: "Isparta" },
      { value: "TR-33", label: "Mersin" },
      { value: "TR-34", label: "Istanbul" },
      { value: "TR-35", label: "İzmir" },
      { value: "TR-36", label: "Kars" },
      { value: "TR-37", label: "Kastamonu" },
      { value: "TR-38", label: "Kayseri" },
      { value: "TR-39", label: "Kırklareli" },
      { value: "TR-40", label: "Kırşehir" },
      { value: "TR-41", label: "Kocaeli" },
      { value: "TR-42", label: "Konya" },
      { value: "TR-43", label: "Kütahya" },
      { value: "TR-44", label: "Malatya" },
      { value: "TR-45", label: "Manisa" },
      { value: "TR-46", label: "Kahramanmaraş" },
      { value: "TR-47", label: "Mardin" },
      { value: "TR-48", label: "Muğla" },
      { value: "TR-49", label: "Muş" },
      { value: "TR-50", label: "Nevşehir" },
      { value: "TR-51", label: "Niğde" },
      { value: "TR-52", label: "Ordu" },
      { value: "TR-53", label: "Rize" },
      { value: "TR-54", label: "Sakarya" },
      { value: "TR-55", label: "Samsun" },
      { value: "TR-56", label: "Siirt" },
      { value: "TR-57", label: "Sinop" },
      { value: "TR-58", label: "Sivas" },
      { value: "TR-59", label: "Tekirdağ" },
      { value: "TR-60", label: "Tokat" },
      { value: "TR-61", label: "Trabzon" },
      { value: "TR-62", label: "Tunceli" },
      { value: "TR-63", label: "Şanlıurfa" },
      { value: "TR-64", label: "Uşak" },
      { value: "TR-65", label: "Van" },
      { value: "TR-66", label: "Yozgat" },
      { value: "TR-67", label: "Zonguldak" },
      { value: "TR-68", label: "Aksaray" },
      { value: "TR-69", label: "Bayburt" },
      { value: "TR-70", label: "Karaman" },
      { value: "TR-71", label: "Kırıkkale" },
      { value: "TR-72", label: "Batman" },
      { value: "TR-73", label: "Şırnak" },
      { value: "TR-74", label: "Bartın" },
      { value: "TR-75", label: "Ardahan" },
      { value: "TR-76", label: "Iğdır" },
      { value: "TR-77", label: "Yalova" },
      { value: "TR-78", label: "Karabük" },
      { value: "TR-79", label: "Kilis" },
      { value: "TR-80", label: "Osmaniye" },
      { value: "TR-81", label: "Düzce" }
    ]
  ],
  [
    "GB",
    [
      { value: "GB-1", label: "Avon" },
      { value: "GB-2", label: "Bedfordshire" },
      { value: "GB-3", label: "Berkshire" },
      { value: "GB-4", label: "Borders" },
      { value: "GB-5", label: "Bristol" },
      { value: "GB-6", label: "Buckinghamshire" },
      { value: "GB-7", label: "Cambridgeshire" },
      { value: "GB-8", label: "Channel Islands" },
      { value: "GB-9", label: "Cheshire" },
      { value: "GB-10", label: "Cleveland" },
      { value: "GB-11", label: "Cornwall" },
      { value: "GB-12", label: "Cumbria" },
      { value: "GB-13", label: "Derbyshire" },
      { value: "GB-14", label: "Devon" },
      { value: "GB-15", label: "Dorset" },
      { value: "GB-16", label: "Durham" },
      { value: "GB-17", label: "East Riding of Yorkshire" },
      { value: "GB-18", label: "East Sussex" },
      { value: "GB-19", label: "Essex" },
      { value: "GB-20", label: "Gloucestershire" },
      { value: "GB-21", label: "Greater Manchester" },
      { value: "GB-22", label: "Hampshire" },
      { value: "GB-23", label: "Herefordshire" },
      { value: "GB-24", label: "Hertfordshire" },
      { value: "GB-25", label: "Humberside" },
      { value: "GB-26", label: "Isle of Man" },
      { value: "GB-27", label: "Isle of Wight" },
      { value: "GB-28", label: "Isles of Scilly" },
      { value: "GB-29", label: "Kent" },
      { value: "GB-30", label: "Lancashire" },
      { value: "GB-31", label: "Leicestershire" },
      { value: "GB-32", label: "Lincolnshire" },
      { value: "GB-33", label: "London" },
      { value: "GB-34", label: "Merseyside" },
      { value: "GB-35", label: "Middlesex" },
      { value: "GB-36", label: "Norfolk" },
      { value: "GB-37", label: "North Yorkshire" },
      { value: "GB-38", label: "Northamptonshire" },
      { value: "GB-39", label: "Northumberland" },
      { value: "GB-40", label: "Nottinghamshire" },
      { value: "GB-41", label: "Oxfordshire" },
      { value: "GB-42", label: "Rutland" },
      { value: "GB-43", label: "Shropshire" },
      { value: "GB-44", label: "Somerset" },
      { value: "GB-45", label: "South Yorkshire" },
      { value: "GB-46", label: "Staffordshire" },
      { value: "GB-47", label: "Suffolk" },
      { value: "GB-48", label: "Surrey" },
      { value: "GB-49", label: "Tyne and Wear" },
      { value: "GB-50", label: "Warwickshire" },
      { value: "GB-51", label: "West Midlands" },
      { value: "GB-52", label: "West Sussex" },
      { value: "GB-53", label: "West Yorkshire" },
      { value: "GB-54", label: "Wiltshire" },
      { value: "GB-55", label: "Worcestershire" },
      { value: "GB-56", label: "Antrim" },
      { value: "GB-57", label: "Down" },
      { value: "GB-58", label: "Fermanagh" },
      { value: "GB-59", label: "Londonderry" },
      { value: "GB-60", label: "Tyrone" },
      { value: "GB-61", label: "Aberdeen City" },
      { value: "GB-62", label: "Aberdeenshire" },
      { value: "GB-63", label: "Angus" },
      { value: "GB-64", label: "Argyll and Bute" },
      { value: "GB-65", label: "Armagh" },
      { value: "GB-66", label: "Carmarthenshire" },
      { value: "GB-67", label: "Clackmannan" },
      { value: "GB-68", label: "Dumfries and Galloway" },
      { value: "GB-69", label: "East Ayrshire" },
      { value: "GB-70", label: "East Dunbartonshire" },
      { value: "GB-71", label: "East Lothian" },
      { value: "GB-72", label: "East Renfrewshire" },
      { value: "GB-73", label: "Edinburgh City" },
      { value: "GB-74", label: "Falkirk" },
      { value: "GB-75", label: "Fife" },
      { value: "GB-76", label: "Glasgow" },
      { value: "GB-77", label: "Highland" },
      { value: "GB-78", label: "Inverclyde" },
      { value: "GB-79", label: "Midlothian" },
      { value: "GB-80", label: "Moray" },
      { value: "GB-81", label: "North Ayrshire" },
      { value: "GB-82", label: "North Lanarkshire" },
      { value: "GB-83", label: "Orkney" },
      { value: "GB-84", label: "Perthshire and Kinross" },
      { value: "GB-85", label: "Renfrewshire" },
      { value: "GB-86", label: "Roxburghshire" },
      { value: "GB-87", label: "Shetland" },
      { value: "GB-88", label: "South Ayrshire" },
      { value: "GB-89", label: "South Lanarkshire" },
      { value: "GB-90", label: "Stirling" },
      { value: "GB-91", label: "West Dunbartonshire" },
      { value: "GB-92", label: "West Lothian" },
      { value: "GB-93", label: "Western Isles" },
      { value: "GB-94", label: "Blaenau Gwent" },
      { value: "GB-95", label: "Bridgend" },
      { value: "GB-96", label: "Caerphilly" },
      { value: "GB-97", label: "Cardiff" },
      { value: "GB-98", label: "Ceredigion" },
      { value: "GB-99", label: "Conwy" },
      { value: "GB-100", label: "Denbighshire" },
      { value: "GB-101", label: "Flintshire" },
      { value: "GB-102", label: "Gwynedd" },
      { value: "GB-103", label: "Isle of Anglesey" },
      { value: "GB-104", label: "Merthyr Tydfil" },
      { value: "GB-105", label: "Monmouthshire" },
      { value: "GB-106", label: "Neath Port Talbot" },
      { value: "GB-107", label: "Newport" },
      { value: "GB-108", label: "Pembrokeshire" },
      { value: "GB-109", label: "Powys" },
      { value: "GB-110", label: "Rhondda Cynon Taff" },
      { value: "GB-111", label: "Swansea" },
      { value: "GB-112", label: "The Vale of Glamorgan" },
      { value: "GB-113", label: "Torfaen" },
      { value: "GB-114", label: "Wrexham" }
    ]
  ],
  [
    "US",
    [
      { value: "AL", label: "Alabama" },
      { value: "AK", label: "Alaska" },
      { value: "AZ", label: "Arizona" },
      { value: "AR", label: "Arkansas" },
      { value: "CA", label: "California" },
      { value: "CO", label: "Colorado" },
      { value: "CT", label: "Connecticut" },
      { value: "DC", label: "District of Columbia" },
      { value: "DE", label: "Delaware" },
      { value: "FL", label: "Florida" },
      { value: "FM", label: "Micronesia" },
      { value: "GA", label: "Georgia" },
      { value: "HI", label: "Hawaii" },
      { value: "ID", label: "Idaho" },
      { value: "IL", label: "Illinois" },
      { value: "IN", label: "Indiana" },
      { value: "IA", label: "Iowa" },
      { value: "KS", label: "Kansas" },
      { value: "KY", label: "Kentucky" },
      { value: "LA", label: "Louisiana" },
      { value: "ME", label: "Maine" },
      { value: "MD", label: "Maryland" },
      { value: "MA", label: "Massachusetts" },
      { value: "MI", label: "Michigan" },
      { value: "MN", label: "Minnesota" },
      { value: "MH", label: "Marshall Islands" },
      { value: "MS", label: "Mississippi" },
      { value: "MO", label: "Missouri" },
      { value: "MT", label: "Montana" },
      { value: "NE", label: "Nebraska" },
      { value: "NV", label: "Nevada" },
      { value: "NH", label: "New Hampshire" },
      { value: "NJ", label: "New Jersey" },
      { value: "NM", label: "New Mexico" },
      { value: "NY", label: "New York" },
      { value: "NC", label: "North Carolina" },
      { value: "ND", label: "North Dakota" },
      { value: "OH", label: "Ohio" },
      { value: "OK", label: "Oklahoma" },
      { value: "OR", label: "Oregon" },
      { value: "PA", label: "Pennsylvania" },
      { value: "PW", label: "Palau" },
      { value: "RI", label: "Rhode Island" },
      { value: "SC", label: "South Carolina" },
      { value: "SD", label: "South Dakota" },
      { value: "TN", label: "Tennessee" },
      { value: "TX", label: "Texas" },
      { value: "UT", label: "Utah" },
      { value: "VT", label: "Vermont" },
      { value: "VA", label: "Virginia" },
      { value: "WA", label: "Washington" },
      { value: "WV", label: "West Virginia" },
      { value: "WI", label: "Wisconsin" },
      { value: "WY", label: "Wyoming" },
      { value: "AS", label: "American Samoa" },
      { value: "GU", label: "Guam" },
      { value: "MP", label: "Northern Mariana Islands" },
      { value: "PR", label: "Puerto Rico" },
      {
        value: "UM",
        label: "United States Minor Outlying Islands"
      },
      { value: "VI", label: "Virgin Islands" }
    ]
  ],
  [
    "VN",
    [
      { value: "VN-1", label: "Hà Nội" },
      { value: "VN-2", label: "Hà Giang" },
      { value: "VN-3", label: "Cao Bằng" },
      { value: "VN-4", label: "Bắc Kạn" },
      { value: "VN-5", label: "Tuyên Quang" },
      { value: "VN-6", label: "Lào Cai" },
      { value: "VN-7", label: "Điện Biên" },
      { value: "VN-8", label: "Lai Châu" },
      { value: "VN-9", label: "Sơn La" },
      { value: "VN-10", label: "Yên Bái" },
      { value: "VN-11", label: "Hòa Bình" },
      { value: "VN-12", label: "Thái Nguyên" },
      { value: "VN-13", label: "Lạng Sơn" },
      { value: "VN-14", label: "Quảng Ninh" },
      { value: "VN-15", label: "Bắc Giang" },
      { value: "VN-16", label: "Phú Thọ" },
      { value: "VN-17", label: "Vĩnh Phúc" },
      { value: "VN-18", label: "Bắc Ninh" },
      { value: "VN-19", label: "Hải Dương" },
      { value: "VN-20", label: "Hải Phòng" },
      { value: "VN-21", label: "Hưng Yên" },
      { value: "VN-22", label: "Thái Bình" },
      { value: "VN-23", label: "Hà Nam" },
      { value: "VN-24", label: "Nam Định" },
      { value: "VN-25", label: "Ninh Bình" },
      { value: "VN-26", label: "Thanh Hóa" },
      { value: "VN-27", label: "Nghệ An" },
      { value: "VN-28", label: "Hà Tĩnh" },
      { value: "VN-29", label: "Quảng Bình" },
      { value: "VN-30", label: "Quảng Trị" },
      { value: "VN-31", label: "Thừa Thiên–Huế" },
      { value: "VN-32", label: "Đà Nẵng" },
      { value: "VN-33", label: "Quảng Nam" },
      { value: "VN-34", label: "Quảng Ngãi" },
      { value: "VN-35", label: "Bình Định" },
      { value: "VN-36", label: "Phú Yên" },
      { value: "VN-37", label: "Khánh Hòa" },
      { value: "VN-38", label: "Ninh Thuận" },
      { value: "VN-39", label: "Bình Thuận" },
      { value: "VN-40", label: "Kon Tum" },
      { value: "VN-41", label: "Gia Lai" },
      { value: "VN-42", label: "Đắk Lắk" },
      { value: "VN-43", label: "Đắk Nông" },
      { value: "VN-44", label: "Lâm Đồng" },
      { value: "VN-45", label: "Bình Phước" },
      { value: "VN-46", label: "Tây Ninh" },
      { value: "VN-47", label: "Bình Dương" },
      { value: "VN-48", label: "Đồng Nai" },
      { value: "VN-49", label: "Bà Rịa–Vũng Tàu" },
      { value: "VN-50", label: "Thành phố Hồ Chí Minh" },
      { value: "VN-51", label: "Long An" },
      { value: "VN-52", label: "Tiền Giang" },
      { value: "VN-53", label: "Bến Tre" },
      { value: "VN-54", label: "Trà Vinh" },
      { value: "VN-55", label: "Vĩnh Long" },
      { value: "VN-56", label: "Đồng Tháp" },
      { value: "VN-57", label: "An Giang" },
      { value: "VN-58", label: "Kiên Giang" },
      { value: "VN-59", label: "Cần Thơ" },
      { value: "VN-60", label: "Hậu Giang" },
      { value: "VN-61", label: "Sóc Trăng" },
      { value: "VN-62", label: "Bạc Liêu" },
      { value: "VN-63", label: "Cà Mau" }
    ]
  ]
]);

import jwtDecode from "jwt-decode";
import {
  AuthrnticationTokenRequest,
  SignInRequest,
  SignInResponse
} from "models/auth";
import { destroyCookie, parseCookies, setCookie } from "nookies";
import { useToast } from "providers/toast";
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState
} from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import * as AuthService from "services/auth";
import { AuthErrors } from "./errors";

type AuthContextType = {
  token?: string | null;
  isAuthenticated: boolean;
  tokenDecoded?: SignInResponse;
  signIn: (params: SignInRequest) => Promise<boolean>;
  authenticationToken: (params: AuthrnticationTokenRequest) => Promise<boolean>;
  signOut: () => void;
  createPassword: (
    password: string,
    confirmation_password: string,
    confirmation_token: string,
    email: string
  ) => void;
};

type AuthProviderType = {
  children: ReactNode;
};

const tokenExpirationDate = 60 * 60 * 24; // 24hrs

const AuthContext = createContext<AuthContextType>({} as AuthContextType);

const AuthProvider = ({ children }: AuthProviderType) => {
  // eslint-disabled-next-line prettier/prettier
  const { password_token: initialToken } = parseCookies();

  const { error, success, warning } = useToast();
  const { t } = useTranslation();
  const [token, setToken] = useState<string | null>(initialToken);
  const navigate = useNavigate();
  const isAuthenticated = useMemo(() => !!token, [token]);

  const tokenDecoded = useMemo(
    () => (token ? jwtDecode<SignInResponse>(token) : undefined),
    [token]
  );
  const errorsResolver = useMemo(
    () => new AuthErrors({ error, warning }, t),
    [error, warning, t]
  );
  const saveData = useCallback((token: string) => {
    setToken(token);
    setCookie(undefined, "password_token", token, {
      maxAge: tokenExpirationDate,
      path: "/"
    });
  }, []);

  const deleteData = useCallback(() => {
    setToken("");
    destroyCookie(undefined, "password_token");
  }, []);

  const signIn = useCallback(
    async (params: SignInRequest) => {
      return await AuthService.signIn(params)
        .then(({ data }) => {
          saveData(data.password_token);
          navigate("/organizations", { replace: true });
          return true;
        })
        .catch(err => {
          errorsResolver.signIn(err);
          return false;
        });
    },
    [saveData, navigate, errorsResolver]
  );

  const authenticationToken = useCallback(
    async (params: AuthrnticationTokenRequest) => {
      return await AuthService.authenticationToken(params)
        .then(({ data }) => {
          saveData(data.password_token);
          navigate("/organizations", { replace: true });
          return true;
        })
        .catch(err => {
          errorsResolver.signIn(err);
          navigate("/sign-in", { replace: true });
          return false;
        });
    },
    [saveData, navigate, errorsResolver]
  );

  const signOut = useCallback(() => {
    deleteData();
    navigate("/sign-in", { replace: true });
  }, [deleteData, navigate]);

  // eslint-disabled-next-line prettier/prettier
  const createPassword = useCallback(
    async (
      password: string,
      confirmation_password: string,
      confirmation_token: string,
      email: string
    ) => {
      return await AuthService.createPassword({
        confirmation_token,
        email,
        password,
        confirmation_password
      })
        .then(() => {
          success({ description: t("alerts.successfullyReset") });
          navigate("/sign-in", { replace: true });
        })
        .catch(errorsResolver.newPassword);
    },
    [errorsResolver, navigate, success, t]
  );

  return (
    <AuthContext.Provider
      value={{
        signIn,
        isAuthenticated,
        tokenDecoded,
        createPassword,
        authenticationToken,
        signOut
      }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);

export default AuthProvider;

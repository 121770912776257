/* eslint-disabled @typescript-eslint/no-explicit-any */
/* eslint-disabled @typescript-eslint/ban-types */
import { html as htmlBeautify } from "js-beautify";
import { HTMLInputDateTimeType } from "models";
import { RefObject } from "react";
import { validateDate } from "./";

// eslint-disabled-next-line @typescript-eslint/no-unnecessary-type-constraint
export const arrayEquals = <T>(first: Array<T>, second: Array<T>) => {
  return (
    first.length === second.length &&
    first.every((val, index) => val === second[index])
  );
};

export const cliboard = (clip?: string) => {
  return navigator.clipboard.writeText(clip || "");
};

// eslint-disable-next-line @typescript-eslint/ban-types
export const debounce = (callback: Function, timeout = 300) => {
  let timer: NodeJS.Timeout;

  const cancel = () => clearTimeout(timer);

  const debounced = (...args: any) => {
    clearTimeout(timer);
    timer = setTimeout(() => callback.apply(this, args), timeout);
  };

  debounced.cancel = cancel;

  return debounced;
};

export const exitElement =
  (ref: RefObject<HTMLElement>, callback?: () => void) => (e: Event) => {
    if (
      e.target !== ref?.current &&
      !ref?.current?.contains(e.target as Node)
    ) {
      callback?.();
    }
  };

export const extractFileContent = async (file: File) => {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    try {
      reader.onload = async ({ target }) =>
        resolve(target?.result?.toString() || "");
    } catch (error) {
      return reject(new Error("Erro ao extrair texto do arquivo"));
    }
    reader.readAsText(file);
  });
};

export const generateUID = () => {
  const numToBase16 = (num: number) => Math.floor(num).toString(16);
  return (
    numToBase16(Date.now() / 1000) +
    " ".repeat(16).replace(/./g, () => numToBase16(Math.random() * 16))
  );
};

export const getFirstAndLastWord = (name = "") => {
  if (!name) return name;
  const names = name.trim().split(" ");
  if (names.length > 1) return `${names[0]} ${names[names.length - 1]}`;
  return name;
};
export const getFirstAndSecoundtWord = (name = "") => {
  if (!name) return name;
  const names = name.trim().split(" ");
  if (names.length > 2) {
    return `${names[0]} ${names[1]} ${names.slice(2).join(" ")}`;
  } else if (names.length === 2) {
    return `${names[0]} ${names[1]}`;
  }
  return names[0];
};

export const htmlFormater = (html = "") => {
  const currentHTML = html
    .replace(/>/g, ">\n")
    .replace(/<\//g, "\n</")
    .replace(/^\s*\n/gm, "");
  return htmlBeautify(currentHTML);
};

export const insertInCursorPointer = (
  ref: RefObject<HTMLTextAreaElement | HTMLInputElement>,
  text: string
) => {
  if (ref.current) {
    const event = new Event("change", { bubbles: true, cancelable: true });
    const { value, selectionStart, selectionEnd } = ref.current;
    const previousText = value.slice(0, selectionStart || value.length);
    const nextText = value.slice(selectionEnd || value.length);
    ref.current.value = `${previousText}${text}${nextText}`;
    ref.current.dispatchEvent(event);
  }
};

// export const intervalIsUpToTwoMonths = (interval: Duration) => {
//   const { years = 0, months = 0, days = 0 } = interval;
//   if (years > 0) return false;
//   else if (months > 2 || (months === 2 && days > 0)) return false;

//   return true;
// };

export const objectToFormData = (item: object) => {
  const formData = new FormData();
  Object.entries(item).map(([key, value]) => formData.append(key, value));
  return formData;
};

export const timeResolver = (
  value: string,
  options?: Intl.DateTimeFormatOptions
) => {
  if (validateDate(value))
    return new Date(value).toLocaleTimeString("pt-BR", options);
  return value;
};

export const isRangeAccepted = (
  interval: Duration,
  type: HTMLInputDateTimeType
) => {
  const { years = 0, months = 0, days = 0 } = interval;
  switch (type) {
    case "month": {
      if (years > 5 || (years === 5 && (months > 0 || days > 0))) return false;
      return true;
    }
    default: {
      if (years > 0) return false;
      else if (months > 2 || (months === 2 && days > 0)) return false;
      return true;
    }
  }
};

import useMap from "hooks/map";
import { OrganizationResponse } from "models";
import { useToast } from "providers/toast";
import { useUser } from "providers/user";
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from "react";
import { useTranslation } from "react-i18next";
import * as OrganizationService from "services/organizations";
import { OrganizationsErrors } from "./errors";

type OrgnaizationContextType = {
  organizations?: Map<string, OrganizationResponse>;
  currentOrganization?: OrganizationResponse;
  loading?: boolean;
  setCodeOrganization: React.Dispatch<React.SetStateAction<string>>;
  getOrganization: (code: string) => OrganizationResponse | undefined;
  getAllOrganization: (value: OrganizationResponse) => void;
  addOrganization: (value: OrganizationResponse) => void;
  editOrganization: (value: OrganizationResponse) => void;
};

type OrgnizationProviderType = {
  children: ReactNode;
};

const OrganizationContext = createContext({} as OrgnaizationContextType);

const OrgnizationProvider = ({ children }: OrgnizationProviderType) => {
  const { t } = useTranslation("organizations");
  const { error, warning, success } = useToast();
  const [loading, setLoading] = useState(false);
  // eslint-disabled-next-line prettier/prettier
  const [organizations, { set, add, edit }] = useMap<
    string,
    OrganizationResponse
  >();

  const [codeOrganization, setCodeOrganization] = useState("");
  const { currentUser } = useUser();

  const handleSuccess = useCallback(
    (isEdit: boolean) => {
      success({
        description: t(`${isEdit ? "alert.editMsg" : "alert.createMsg"}`)
      });
    },
    [success, t]
  );

  const errorsResolver = useMemo(
    () => new OrganizationsErrors({ error, warning }, t),
    [error, warning, t]
  );
  const getAllOrganization = useCallback(async () => {
    setLoading(true);
    if (!currentUser) return;

    await OrganizationService.GetAllOrganization(currentUser?._id)
      .then(({ data }) => {
        set(
          new Map(data.map(organization => [organization?.code, organization]))
        );
      })
      .catch(errorsResolver.defaultError);
    setLoading(false);
  }, [currentUser, errorsResolver, set]);

  const getOrganization = useCallback(
    (code: string) => organizations?.get(code),
    [organizations]
  );

  const addOrganization = useCallback(
    async ({ ...request }) => {
      setLoading(true);
      await OrganizationService.AddOrganization(request as OrganizationResponse)
        .then(({ data }) => {
          handleSuccess(false);
          add(data?.code, data as OrganizationResponse);
        })
        .catch(err => error({ description: err?.response?.data.error }));
      setLoading(false);
    },
    [error, handleSuccess, add]
  );

  const editOrganization = useCallback(
    async ({ ...data }) => {
      setLoading(true);
      warning({ description: "Aguarde, suas alterações estão sendo salvas!" });
      const { owner_id } = data;
      await OrganizationService.EditOrganization(data as OrganizationResponse)
        .then(({ data }) => {
          handleSuccess(true);
          edit(data?.code, { ...data, owner_id });
        })
        .catch(errorsResolver.defaultError);
      setLoading(false);
    },
    [errorsResolver, handleSuccess, warning, edit]
  );

  useEffect(() => {
    if (codeOrganization) getOrganization(codeOrganization);
  }, [codeOrganization, getOrganization]);

  useEffect(() => {
    getAllOrganization();
  }, [getAllOrganization]);

  return (
    <OrganizationContext.Provider
      value={{
        organizations,
        loading,
        setCodeOrganization,
        getAllOrganization,
        getOrganization,
        addOrganization,
        editOrganization
      }}>
      {children}
    </OrganizationContext.Provider>
  );
};

export const useOrganization = () => useContext(OrganizationContext);

export default OrgnizationProvider;

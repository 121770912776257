import { Header, NotificationsItemsContainer } from "./styles";
import NotificationViewModel from "./viewModel";
import { ReactNode } from "react";

export type NotificationsItemsType = {
  children: ReactNode;
};

const NotificationsItems = ({ children }: NotificationsItemsType) => {
  const viewModel = NotificationViewModel();

  const { t } = viewModel;

  return (
    <NotificationsItemsContainer>
      <Header>
        <h1>{t("notifications.title")}</h1>
      </Header>
      <div>{children}</div>
    </NotificationsItemsContainer>
  );
};

export default NotificationsItems;

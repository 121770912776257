/* eslint-disabled prettier/prettier */
import axios, { AxiosRequestConfig } from "axios";
import { destroyCookie, parseCookies } from "nookies";
import { ls } from "utils";

const CancelToken = axios.CancelToken;
const pendingRequest = new Map();

const service = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  timeout: 300000
});

service.interceptors.request.use(
  request => {
    const language = ls.getItem("i18nextLng");
    const { password_token: currentToken } = parseCookies();
    if (currentToken) {
      request.headers = {
        ...request.headers,
        Authorization: currentToken,
        "Accept-Language": language ?? "pt"
      };
    }

    const requestKey = getReqKey(request);

    if (pendingRequest.has(requestKey)) removeReqKey(requestKey);
    else
      request.cancelToken = new CancelToken(cancel =>
        pendingRequest.set(requestKey, cancel)
      );

    return request;
  },
  error => Promise.reject(error)
);

service.interceptors.response.use(
  response => {
    removeReqKey(getReqKey(response.config));

    return response;
  },

  error => {
    removeReqKey(getReqKey(error.config));

    if (error?.response?.status === 401) {
      const { password_token: currentToken } = parseCookies();
      if (currentToken) {
        window.location.reload();
        destroyCookie(undefined, "password_token");
      }
    }
    return Promise.reject(error);
  }
);

const getReqKey = (config: AxiosRequestConfig) =>
  `${config?.method}-${config?.url}}`;

const removeReqKey = (key: string) => {
  const cancelToken = pendingRequest.get(key);
  cancelToken?.(key);
  pendingRequest.delete(key);
};

export default service;

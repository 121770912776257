import axios, { AxiosError } from "axios";
import { CreateToastType } from "providers/toast";
import { TFunction } from "react-i18next";

type ToastType = {
  error: CreateToastType;
  warning: CreateToastType;
};
export class Errors {
  error: CreateToastType;
  warning: CreateToastType;
  t: TFunction;

  constructor({ error, warning }: ToastType, translation: TFunction) {
    this.error = error;
    this.warning = warning;
    this.t = translation;
  }

  defaultError = (reason: AxiosError) => {
    if (!axios.isCancel(reason))
      this.error({ description: this.t("alerts.unforeseen") });
  };
}

import Content from "./content";
import Header from "./header";
import SideBar from "./sideBar";
import HeaderProvider from "providers/header";
import OrgnizationProvider from "providers/organization";
import UserProvider from "providers/user";

import { LayoutContainer, LayoutContent } from "./styles";

const Layout = () => {
  return (
    <HeaderProvider>
      <UserProvider>
        <OrgnizationProvider>
          <LayoutContainer>
            <SideBar />
            <LayoutContent>
              <Header />
              <Content />
            </LayoutContent>
          </LayoutContainer>
        </OrgnizationProvider>
      </UserProvider>
    </HeaderProvider>
  );
};

export default Layout;

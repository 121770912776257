import { OptionType, StatusType } from "models";

export const i18nLanguage = "i18nextLng";

export const itemsPerPaginations: number[] = [12, 24, 48];

export const genres: OptionType<string>[] = [
  {
    value: "m",
    label: "genres.masculine"
  },
  {
    value: "f",
    label: "genres.feminine"
  },
  {
    value: "n",
    label: "genres.notDeclare"
  }
];

export const languages = ["pt", "en"];

export const sendingModes: OptionType<string>[] = [
  {
    value: "vertical",
    label: "Vertical"
  },
  {
    value: "horizontal",
    label: "Horizontal"
  }
];

export const plansType: OptionType<string>[] = [
  {
    value: "consumo",
    label: "Consumo"
  },
  {
    value: "license",
    label: "Licença"
  }
];
export const statusType: StatusType<any>[] = [
  {
    value: "true",
    label: "Ativo"
  },
  {
    value: "false",
    label: "Inativo"
  }
];

export const frequecyType: StatusType<string>[] = [
  {
    value: "Diary",
    label: "Diario"
  },
  {
    value: "Weekly",
    label: "Semanal"
  },
  {
    value: "Biweekly",
    label: "Quinzenal"
  },
  {
    value: "Monthly",
    label: "Por mês"
  }
];

export const periods: OptionType<number>[] = [
  { value: 1, label: "1" },
  { value: 2, label: "2" },
  { value: 3, label: "3" },
  { value: 4, label: "4" },
  { value: 5, label: "5" },
  { value: 6, label: "6" },
  { value: 7, label: "7" },
  { value: 8, label: "8" },
  { value: 9, label: "9" },
  { value: 10, label: "10" },
  { value: 11, label: "11" },
  { value: 12, label: "12" },
  { value: 13, label: "13" },
  { value: 14, label: "14" },
  { value: 15, label: "15" },
  { value: 16, label: "16" },
  { value: 17, label: "17" },
  { value: 18, label: "18" },
  { value: 19, label: "19" },
  { value: 20, label: "20" },
  { value: 21, label: "21" },
  { value: 22, label: "22" },
  { value: 23, label: "23" },
  { value: 24, label: "24" },
  { value: 25, label: "25" },
  { value: 26, label: "26" },
  { value: 27, label: "27" },
  { value: 28, label: "28" },
  { value: 29, label: "29" },
  { value: 30, label: "30" },
  { value: 31, label: "31" }
];

export const joditEditorButtons =
  "bold,italic,underline,strikethrough,|,eraser,ul,ol,|,font,fontsize,brush,paragraph,classSpan,lineHeight,superscript,subscript,|,image,video,file,|,spellcheck,|,cut,copy,paste,selectall,hr,table,link,|,indent,outdent,justify,left,center,right,|,undo,redo,";

export const emptyValue = "empty";

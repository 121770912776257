import { AxiosRequestConfig } from "axios";
import { OrganizationRequest, OrganizationResponse } from "models";
import service from ".";

export const GetAllOrganization = async (
  user_id: string,
  config?: AxiosRequestConfig
) =>
  await service.get<OrganizationResponse[]>("/organizations", {
    ...config,
    params: { user_id }
  });

export const GetOrganization = async (
  code_account: string,
  config?: AxiosRequestConfig
) =>
  await service.get<OrganizationResponse>("/organization", {
    ...config,
    params: { code_account }
  });

export const AddOrganization = async (
  params: OrganizationRequest,
  config?: AxiosRequestConfig
) => await service.post<OrganizationResponse>("/organization", params, config);

export const EditOrganization = async (
  params: OrganizationRequest,
  config?: AxiosRequestConfig
) => await service.put<OrganizationResponse>("/organization", params, config);

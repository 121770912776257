import { ReactComponent as SecotrIcon } from "assets/sidebar/sector.svg";
import { Option } from "components/select";
import { useUser } from "providers/user";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getFirstAndSecoundtWord } from "utils";
import { Container, NavSelect, SectorContainer, SelectLabel } from "./styles";

type NavDropdownType = {
  justIcon?: boolean;
};

const NavSector = ({ justIcon }: NavDropdownType) => {
  const [value, setValue] = useState("");
  const { t } = useTranslation("components");
  const { currentUser, editUser } = useUser();

  useEffect(() => {
    if (currentUser) setValue(currentUser.current_organization?._id);
  }, [currentUser]);

  const handleChangeOrganization = useCallback(
    async (idOrg: string) => {
      editUser(currentUser?._id as string, {
        _id: currentUser?._id as string,
        current_organization: idOrg
      });
    },
    [currentUser, editUser]
  );

  return (
    <Container>
      <SecotrIcon width="2.1rem" height="2.1rem" />
      {!justIcon && (
        <SectorContainer>
          <SelectLabel>{t("sideBar.organization")}</SelectLabel>
          <NavSelect
            fullWidth
            value={value}
            onChange={value => {
              setValue(value as string);
              handleChangeOrganization(value as string);
            }}>
            {currentUser &&
              currentUser?.organizations
                .filter(org => org.active)
                ?.map(org => (
                  <Option key={org?._id} value={org?._id}>
                    {getFirstAndSecoundtWord(org?.name)}
                  </Option>
                ))}
          </NavSelect>
        </SectorContainer>
      )}
    </Container>
  );
};

export default NavSector;

import { ReactComponent as ErrorIcon } from "assets/icons/error.svg";
import { ReactComponent as InfoIcon } from "assets/icons/info.svg";
import { ReactComponent as SuccessIcon } from "assets/icons/success.svg";
import { ReactComponent as WarningIcon } from "assets/icons/warning.svg";
import { memo, useEffect, useRef } from "react";
import {
  Container,
  CustomButton,
  IconContent,
  InfoContent,
  ToastOptionTypes,
  ToastStyleType
} from "./styles";

export type ToastType = ToastStyleType & {
  title?: string;
  description: string;
  action?: {
    text: string;
    onClick: () => void;
  };
  removeMe: () => void;
};

const getTypeIcon = (type: ToastOptionTypes) => {
  switch (type) {
    case "error":
      return <ErrorIcon />;
    case "warning":
      return <WarningIcon />;
    case "info":
      return <InfoIcon />;
    default:
      return <SuccessIcon />;
  }
};

const Toast = ({
  type = "success",
  title,
  description,
  action,
  removeMe
}: ToastType) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    const currentRef = ref?.current;

    const callAsyncFunction = async () => {
      await new Promise(() => {
        timer = setTimeout(() => removeMe(), 4000);
        currentRef?.addEventListener("mouseenter", () => {
          clearTimeout(timer);
        });
        currentRef?.addEventListener("mouseleave", () => {
          timer = setTimeout(() => removeMe(), 2000);
        });
      });
    };

    callAsyncFunction();

    return () => {
      clearTimeout(timer);
    };
  }, [removeMe]);

  return (
    <Container type={type} ref={ref}>
      <IconContent type={type}>{getTypeIcon(type)}</IconContent>
      <InfoContent>
        <p>
          <b>{title}</b>
        </p>
        <p>{description}</p>
        {action && (
          <CustomButton onClick={action.onClick}>{action.text}</CustomButton>
        )}
      </InfoContent>
    </Container>
  );
};

export default memo(Toast);

export type { ToastOptionTypes };
// eslint-disabled-next-line prettier/prettier
